import { ModalAddTeacherUI } from './ModalAddTeacher.Presenter';
import { modalAddTeacherData, ModalAddTeacherType } from 'recoil/modalAddTeacher';
import { dropdownNormalData } from 'recoil/dropdownNormal';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { TableRecoilType, tableState } from 'recoil/table';
import { userInfoState, UserInfoType } from 'recoil/userInfo';
import { useCallback, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { fetchGetApi, fetchPostApi } from 'utils/api';
import React from 'react';

export const ModalAddTeacherContainer = React.memo(function ModalAddTeacherContainer() {
  const location = useLocation();
  const tableStateData = useRecoilValue<TableRecoilType>(tableState);
  const setDropdownNormal = useSetRecoilState(dropdownNormalData);
  const [modaAddTeacher, setModalAddTeacher] = useRecoilState<ModalAddTeacherType>(modalAddTeacherData);
  const userInfo = useRecoilValue<UserInfoType>(userInfoState);

  const getTeachers = useQuery('getTeachers', fetchGetApi(`/customers/${userInfo.center_id}/accounts/teachers`), {
    retry: 0,
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess: (res: GetTeachersResType) => {
      const new_teacher_list = res.data.results.map(row => {
        let disabled: boolean;
        if (modaAddTeacher.disableTeacerIds.includes(row.id)) {
          disabled = true;
        } else {
          disabled = false;
        }

        return {
          id: row.id,
          teacher_id: row.teacher_id,
          name: row.name,
          selected: false,
          disabled: disabled,
        };
      });
      setModalAddTeacher(prev => ({ ...prev, teacherList: new_teacher_list }));
    },
    onError: e => {
      console.log(e);
    },
  });

  const addTeacherInClass = useMutation(fetchPostApi);

  useEffect(() => {
    if (modaAddTeacher.visible) {
      getTeachers.refetch();
    }
  }, [modaAddTeacher.visible]);

  const onClose = useCallback(() => {
    setDropdownNormal(prev => ({ ...prev, normal: { title: '편집' } }));
    setModalAddTeacher(() => ({
      visible: false,
      teacherList: [],
      disableTeacerIds: [],
      onSuccess: () => {
        return;
      },
    }));
  }, []);

  const onClickRow = useCallback(
    (idx: number) => {
      // row 클릭 했을 때, 메서드 작성
      const new_teacher_list = modaAddTeacher.teacherList.map((teacher, tIdx) => {
        if (idx === tIdx) {
          return {
            ...teacher,
            selected: true,
          };
        } else {
          return {
            ...teacher,
            selected: false,
          };
        }
      });
      setModalAddTeacher(prev => ({ ...prev, teacherList: new_teacher_list }));
    },
    [modaAddTeacher.teacherList],
  );

  const onSubmit = useCallback(async () => {
    // 부담임 추가 하는 api에 요청
    const selected_teacher = modaAddTeacher.teacherList.find(teacher => {
      return teacher.selected;
    });

    if (selected_teacher) {
      const teacher_id = selected_teacher.teacher_id;
      const class_ids = tableStateData['class'].tableItems
        .filter((tableItem, tIdx) => {
          return tableStateData['class'].checkedIdx.includes(tIdx);
        })
        .map(tableItem => Number(tableItem.id));

      let uri = `/customers/${userInfo.center_id}/classes`;
      const args: { teacher_id: number; class_ids?: number[] } = { teacher_id };
      if (class_ids.length > 1) {
        args.class_ids = class_ids;
      } else {
        uri += `/${class_ids[0]}`;
      }
      uri += '/subteachers';

      const res: ResType = await addTeacherInClass.mutateAsync({ uri, args });
      if (res.result) {
        location.state = 'add_multi_teacher';
        modaAddTeacher.onSuccess();
        onClose();
      }
    }
  }, [modaAddTeacher.teacherList, tableStateData]);

  const checkButtonDisabled = useMemo(() => {
    return !modaAddTeacher.teacherList.some(teacher => teacher.selected);
  }, [modaAddTeacher.teacherList]);

  const props_obj = {
    visible: modaAddTeacher.visible,
    teacherList: modaAddTeacher.teacherList ? modaAddTeacher.teacherList : [],
    onClose,
    onClickRow,
    onSubmit,
    checkButtonDisabled,
  };
  return <ModalAddTeacherUI {...props_obj} />;
});
