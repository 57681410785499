import {
  styled,
  InputLabel as MuiInputLabel,
  Tooltip as MuiTooltip,
  TooltipProps,
  tooltipClasses,
} from '@mui/material';

export const InputLabel = styled(MuiInputLabel)({
  width: '100%',
  height: '2.25rem',
  display: 'flex',
  alignItems: 'center',
  fontSize: '1rem',
  fontWeight: '500',
  marginTop: '0.5rem',
  // marginBottom: '0.45rem',
  color: '#505050',
  '& .MuiInputLabel-asterisk': {
    color: 'var(--red)',
  },
});

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} arrow classes={{ popper: className }} disableHoverListener />
))(({ placement }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#3f3f3f',
    left: placement === 'top-start' ? '1.5rem !important' : '0px !important',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#3f3f3f',
    fontSize: '1rem',
    maxWidth: 'unset',
  },
  inset: placement === 'top-start' ? 'auto auto 0px -1.6rem !important' : 'unset',
}));
