import { atom, SetterOrUpdater } from 'recoil';

export interface ModalUpAndDownContentsType {
  id: number | string;
  label: string;
  value: number;
  value_idx: number;
  kor_unit: string;
  selctableValues: number[];
}

export interface ModalUpAndDownDataType {
  id: string;
  visible: boolean;
  title: string;
  subTitle?: string;
  contents: ModalUpAndDownContentsType[];
  oldValue: ModalUpAndDownContentsType[];
  curValue: ModalUpAndDownContentsType[];
  updateValue: (modalUpAndDown: ModalUpAndDownDataType, contents: ModalUpAndDownContentsType[]) => void;
  clickCancel: () => void;
}

export const modalUpAndDownData = atom<ModalUpAndDownDataType>({
  key: 'modalUpAndDown',
  default: {
    id: '',
    visible: false,
    title: '',
    subTitle: '',
    contents: [],
    oldValue: [],
    curValue: [],
    updateValue: (modalUpAndDown: ModalUpAndDownDataType, contents: ModalUpAndDownContentsType[]) => {
      return;
    },
    clickCancel: () => {
      return;
    },
  },
});

interface ModalUpAndDownSetter {
  setModalUpAndDown: SetterOrUpdater<ModalUpAndDownDataType>;
}

export const closeModalUpAndDown = ({ setModalUpAndDown }: ModalUpAndDownSetter) => {
  setModalUpAndDown({
    id: '',
    visible: false,
    title: '',
    subTitle: '',
    contents: [],
    oldValue: [],
    curValue: [],
    updateValue: (modalUpAndDown: ModalUpAndDownDataType, contents: ModalUpAndDownContentsType[]) => {
      return;
    },
    clickCancel: () => {
      return;
    },
  });
};
