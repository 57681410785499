import { styled, Box } from '@mui/material';
import {
  Wrap as OriginalWrap,
  Header as OriginalHeader,
} from 'components/common/ModalAddTeacher/ModalAddTeacher.Styles';
export { Modal, Wrapper } from 'components/common/ModalAddTeacher/ModalAddTeacher.Styles';
export { Box } from '@mui/material';

export const Wrap = styled(OriginalWrap)({
  borderRadius: '1.5rem',
  background: 'transparent',
});

export const Header = styled(OriginalHeader)(props => ({
  background: props.theme.palette.longvoca_4.main,
  justifyContent: 'center',
  '@media (max-width: 1903px)': {
    fontSize: '1.25rem',
  },
  '@media (max-width: 1263px)': {
    height: '3.75rem',
  },
  '@media (max-width: 959px)': {
    height: '5rem',
    fontSize: '1.625rem',
  },
  '@media (max-width: 767px)': {
    height: '4rem',
    fontSize: '1.35rem',
  },
}));

export const Body = styled(Box)(props => ({
  width: '60rem',
  height: '32rem',
  backgroundColor: props.theme.palette.white.main,
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: '1rem',
  padding: '1rem',
  '@media (max-width: 959px)': {
    width: '52rem',
    height: '28rem',
  },

  '& .profile-option': {
    width: '100%',
    borderRadius: '1rem',
    cursor: 'pointer',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > img': {
      width: '100%',
    },
  },
}));
