import {
  Modal,
  Wrapper,
  Wrap,
  Header,
  HeaderIconWrap,
  Body,
  Top,
  Middle,
  Row,
  Name,
  Deco,
  Bottom,
} from './ModalAddTeacher.Styles';
import { ButtonContainer } from 'components/common/Button/Button.Container';
import { Box } from '@mui/material';
import { IoCloseSharp } from 'react-icons/io5';
import { TeacherRowDataType } from 'recoil/modalAddTeacher';
import React from 'react';
import { RiArrowRightSLine } from 'react-icons/ri';

export interface ModalAddTeacherVAProps {
  teacherList: TeacherRowDataType[];
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onClickRow: (idx: number) => void;
  checkButtonDisabled: boolean;
}

export const ModalAddTeacherUI = React.memo(function ModalAddTeacherUI({
  visible,
  teacherList,
  onClose,
  onClickRow,
  onSubmit,
  checkButtonDisabled,
}: ModalAddTeacherVAProps) {
  return (
    <Modal open={visible} onClose={onClose}>
      <Wrapper>
        <Wrap>
          <Header>
            <Box component={'span'}>관리자 추가</Box>
            <HeaderIconWrap onClick={onClose}>
              <IoCloseSharp />
            </HeaderIconWrap>
          </Header>
          <Body>
            <Top>
              <Box>관리자 목록</Box>
            </Top>
            <Middle>
              {teacherList.length ? (
                teacherList.map((teacher, tIdx) => {
                  return (
                    <Row
                      key={`teacher_${tIdx}`}
                      className={teacher.disabled ? 'disabled' : ''}
                      selected={teacher.selected}
                      onClick={teacher.disabled ? undefined : () => onClickRow(tIdx)}
                    >
                      <Deco
                        sx={
                          teacher.disabled
                            ? { borderLeft: '1px dotted #e0e0e0', borderBottom: '1px dotted #e0e0e0' }
                            : {}
                        }
                      />
                      <Name>
                        <Box>{teacher.name}</Box>
                      </Name>
                    </Row>
                  );
                })
              ) : (
                <Row className='disabled'>
                  <Deco /> 등록된 관리자가 없습니다.
                </Row>
              )}
            </Middle>
            <Bottom>
              <ButtonContainer
                variant='contained'
                color='green'
                sx={{ width: 1, height: 1 }}
                onClick={onSubmit}
                disabled={checkButtonDisabled}
              >
                확인
              </ButtonContainer>
            </Bottom>
          </Body>
        </Wrap>
      </Wrapper>
    </Modal>
  );
});
