import { Modal, Wrapper, Wrap, CalendarPicker, Title } from './ModalCalendar.Styles';
import { ButtonContainer } from 'components/common/Button/Button.Container';
import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ModalCalendarDataType } from 'recoil/modalCalendar';
import React from 'react';
import { setLocaleDateString } from 'utils/tools';

interface ModalCalendarVAProps extends Omit<ModalCalendarDataType, 'minDate'> {
  minDate?: Date;
  calendarKey: string;
  curYear: number;
  visible: boolean;
  onYearChange: (month: any) => void;
  onMonthChange: (month: any) => void;
  onChange: (date: Date | null | unknown) => void;
  onSettingView: (type: string) => void;
  onCloseModal: () => void;
  shouldDisableDate?: (date: any) => boolean;
}

export const ModalCalendarUI = React.memo(function ModalCalendarUI({
  curYear,
  minDate,
  maxDate,
  calendarKey,
  visible,
  openTo,
  view,
  views,
  value,
  localeDateString,
  onYearChange,
  onMonthChange,
  onChange,
  onSettingView,
  onCloseModal,
  onCheckReservationDate,
  onChangeReservationDate,
  onDeleteReservationDate,
  shouldDisableDate,
}: ModalCalendarVAProps) {
  const calendar_picker_props = {
    openTo,
    minDate,
    maxDate,
    date: value,
    view,
    views,
    onYearChange,
    onMonthChange,
    onChange,
    shouldDisableDate,
  };

  return (
    <Modal open={visible} onClose={onCloseModal}>
      <Wrapper>
        <Wrap>
          <Title>
            <Box component={'h4'}>{curYear}</Box>
            <Box component={'h2'}>
              {calendarKey.includes('reservation_deployment') && value
                ? setLocaleDateString(value, 'kor')
                    .split(' ')
                    .map((dItem, dIdx) => (
                      <Box component={'span'} key={`date_item_${dIdx}`} onClick={() => onSettingView(dItem)}>
                        {dItem}&nbsp;
                      </Box>
                    ))
                : localeDateString.split(' ').map((dItem, dIdx) => (
                    <Box component={'span'} key={`date_item_${dIdx}`} onClick={() => onSettingView(dItem)}>
                      {dItem}&nbsp;
                    </Box>
                  ))}
            </Box>
          </Title>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CalendarPicker
              {...calendar_picker_props}
              className={
                calendarKey === 'dailyReport' || calendarKey === 'realtimeInquiry'
                  ? 'dailyReport'
                  : calendarKey.includes('reservation')
                  ? 'reservation'
                  : ''
              }
              shouldDisableDate={shouldDisableDate}
            />
          </LocalizationProvider>
          {calendarKey.includes('reservation_deployment') ? (
            calendarKey.includes('1') ? (
              <ButtonContainer
                color='green'
                variant='contained'
                sx={{
                  pt: '1rem',
                  pb: '1rem',
                  width: 1,
                  borderRadius: 'unset',
                }}
                onClick={() => (onCheckReservationDate ? onCheckReservationDate(value) : undefined)}
                disabled={value ? false : true}
              >
                확인
              </ButtonContainer>
            ) : (
              <Box sx={{ display: 'flex', width: 1 }}>
                <ButtonContainer
                  color='orange_3'
                  variant='contained'
                  sx={{
                    pt: '1rem',
                    pb: '1rem',
                    width: 0.5,
                    borderRadius: 'unset',
                  }}
                  onClick={() => (onDeleteReservationDate ? onDeleteReservationDate() : undefined)}
                  disabled={value ? false : true}
                >
                  삭제
                </ButtonContainer>
                <ButtonContainer
                  color='green'
                  variant='contained'
                  sx={{
                    pt: '1rem',
                    pb: '1rem',
                    width: 0.5,
                    borderRadius: 'unset',
                  }}
                  onClick={() => (onChangeReservationDate ? onChangeReservationDate(value) : undefined)}
                  disabled={value ? false : true}
                >
                  변경
                </ButtonContainer>
              </Box>
            )
          ) : (
            <ButtonContainer color='black' sx={{ pt: '1rem', pb: '1rem', width: 1 }} onClick={onCloseModal}>
              취소
            </ButtonContainer>
          )}
        </Wrap>
      </Wrapper>
    </Modal>
  );
});
