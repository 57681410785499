import { atom, SetterOrUpdater } from 'recoil';
import { CalendarPickerView } from '@mui/x-date-pickers';

export interface ModalCalendarDataType {
  visible: boolean;
  calendarKey: string;
  openTo?: CalendarPickerView;
  views?: CalendarPickerView[];
  view?: CalendarPickerView;
  requestedMonthObJ?: { [key: string]: string[] };
  minDate: Date;
  maxDate: Date | null;
  value: Date | null;
  localeDateString: string;
  onSuccess: (date: Date) => void;
  onCheckReservationDate?: (date: Date | null) => void;
  onChangeReservationDate?: (date: Date | null) => void;
  onDeleteReservationDate?: () => void;
  callBackMonthChange?: (date: Date, requestedMonthObJ?: { [key: string]: string[] }) => void;
  shouldDisableDate?: (date: Date) => boolean;
}

export const modalCalendarData = atom<ModalCalendarDataType>({
  key: 'modalCalendar',
  default: {
    calendarKey: '',
    visible: false,
    openTo: 'day',
    views: [],
    view: 'day',
    minDate: new Date('1950-01-01'),
    maxDate: new Date(),
    value: null,
    localeDateString: '',
    onSuccess: (date: Date) => {
      return;
    },
  },
});

interface ModalCalendarSetter {
  setModalCalendar: SetterOrUpdater<ModalCalendarDataType>;
  callBackMonthChange?: (date: any) => void;
  shouldDisableDate?: (date: Date) => boolean;
}

export const openModalCalendar = (
  { setModalCalendar }: ModalCalendarSetter,
  option: Partial<ModalCalendarDataType>,
) => {
  const { shouldDisableDate, callBackMonthChange } = option;

  setModalCalendar(prev => ({
    ...prev,
    visible: true,
    shouldDisableDate,
    callBackMonthChange,
    ...option,
  }));
};

export const closeModalCalendar = ({ setModalCalendar }: ModalCalendarSetter) => {
  setModalCalendar(prev => {
    return {
      ...prev,
      calendarKey: '',
      visible: false,
      openTo: 'day',
      views: [],
      view: 'day',
      minDate: new Date('1950-01-01'),
      maxDate: new Date(),
      value: null,
      localeDateString: '',
      onSuccess: (date: Date) => {
        return;
      },
      callBackMonthChange: (date: Date, requestedMonthObJ?: { [key: string]: string[] }) => {
        return;
      },
    };
  });
};

export const resetModalCalendar = ({ setModalCalendar }: ModalCalendarSetter) => {
  setModalCalendar(prev => {
    return {
      ...prev,
      calendarKey: '',
      visible: false,
      openTo: 'day',
      views: [],
      view: 'day',
      minDate: new Date('1950-01-01'),
      maxDate: new Date(),
      value: null,
      localeDateString: '',
      onSuccess: (date: Date) => {
        return;
      },
      callBackMonthChange: (date: Date, requestedMonthObJ?: { [key: string]: string[] }) => {
        return;
      },
      requestedMonthObJ: {},
    };
  });
};
