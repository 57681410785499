import { Menu, DropdownButton } from './DropdownNormal.Styles';
import { Box, MenuItem } from '@mui/material';
import { GoTriangleDown } from 'react-icons/go';
import { ButtonProps } from '@mui/material';
import React from 'react';

export interface DropdownNormalVAProps {
  // 필요 props 정리
  dropdownContents: { id: number | string | null; content: string }[];
  open: boolean;
  anchorEl: null | HTMLElement;
  dropdownTitle?: string;
  title?: string;
  disabled?: boolean;
  type?: 'circle';
  endIcon?: React.ReactNode;
  className?: string;
  variant?: ButtonProps['variant'];
  color?: ButtonProps['color'];
  onClickDropdownButton: (event: React.MouseEvent<HTMLElement>) => void;
  onClickMenu: (idx: number) => void;
  onCloseMenu: () => void;
  setMenuDisabled: (text: string) => boolean;
}

export const DropdownNormalUI = React.memo(function DropdownNormalUI({
  dropdownContents,
  open,
  anchorEl,
  disabled,
  dropdownTitle,
  title,
  type,
  onClickDropdownButton,
  onClickMenu,
  onCloseMenu,
  setMenuDisabled,
  endIcon,
  variant,
  color,
  className,
}: DropdownNormalVAProps) {
  return (
    <>
      <DropdownButton
        id='demo-customized-button'
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant={variant ? variant : 'outlined'}
        disableElevation
        color={color ? color : 'blue'}
        onClick={onClickDropdownButton}
        endIcon={variant ? null : endIcon ? endIcon : <GoTriangleDown />}
        disabled={disabled}
        uitype={type}
      >
        <Box component={'span'}>{dropdownTitle ? dropdownTitle : title}</Box>
        {variant ? <GoTriangleDown /> : null}
      </DropdownButton>
      <Menu
        id='demo-customized-menu'
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={onCloseMenu}
        className={className}
      >
        {dropdownContents && dropdownContents.length > 0
          ? dropdownContents.map((dropdownContent, idx) => {
              return (
                <MenuItem
                  onClick={() => onClickMenu(idx)}
                  disableRipple
                  key={`menu_item_${idx}`}
                  disabled={setMenuDisabled(dropdownContent.content)}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      color: dropdownContent.id == 0 && dropdownContent.content == '임시반' ? 'rgb(159, 159, 159)' : '',
                    }}
                  >
                    {dropdownContent.content.replace('::disabled', '').replace('::forbidden', '')}
                  </Box>
                </MenuItem>
              );
            })
          : null}
      </Menu>
    </>
  );
});
