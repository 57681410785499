import { styled, Box, TextField as MuiTextField, IconButton } from '@mui/material';
import { ButtonContainer } from 'components/common/Button/Button.Container';
import { IoMdDocument } from 'react-icons/io';

export { Modal, Wrapper } from 'components/common/ModalCheck/ModalCheck.Styles';

interface LogoWrapProps {
  uploaded: string;
}

export const LogoWrap = styled(Box)<LogoWrapProps>(props => ({
  width: '480px',
  minHeight: props.uploaded === 'true' ? '37.25rem' : '19rem',
  backgroundColor: props.theme.palette.white.main,
}));

export const Top = styled(Box)(props => ({
  width: '100%',
  height: '15.25rem',
  minHeight: '170px',
  padding: '1.5rem 2.25rem',
  '& > div': {
    width: '100%',
    height: '100%',
    border: `1.5px dashed ${props.theme.palette.gray_3.main}`,
    padding: '5% 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

export const Notice = styled(Box)(() => ({
  height: '40%',
  fontSize: '1.3rem',
  color: 'rgba(0, 0, 0, 0.6)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  '& > span': {
    '&:first-of-type': {
      lineHeight: '2rem',
    },
    '&:last-of-type': {
      fontSize: '1rem',
    },
  },
}));

export const FileWrap = styled(Box)(props => ({
  width: '100%',
  height: '50%',
  minHeight: '40px',
  color: props.theme.palette.gray_1.main,
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

interface UploadedProps {
  uploaded: string;
}

export const FileIcon = styled(IoMdDocument)<UploadedProps>(props => {
  const color = props.uploaded == 'true' ? props.theme.palette.blue.main : props.theme.palette.gray_1.main;
  return {
    width: '2.25rem',
    height: '2.25rem',
    color,
  };
});

export const TextField = styled(MuiTextField)<UploadedProps>(props => {
  const color = props.uploaded == 'true' ? props.theme.palette.blue.main : props.theme.palette.gray_1.main;
  return {
    width: '55%',
    height: '80%',
    minHeight: '40px',
    marginLeft: '1rem',
    zIndex: 0,
    position: 'relative',
    '& > div': {
      height: '100%',
      input: {
        cursor: 'pointer',
        textAlign: 'left',
        color: props.theme.palette.gray_1.main,
      },
    },
    '& > label.MuiInputLabel-root': {
      fontSize: '1.35rem',
      transform: 'translate(12px, -9px) scale(0.75)',
      color,
    },
    '& > fieldset.MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
    },
  };
});

export const FileButton = styled(ButtonContainer)({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  left: 'calc(25% + 2.25rem) ',

  p: {
    display: 'flex',
    maxWidth: '8rem',
    textOverflow: 'ellipsis',
    span: {
      overflowX: 'hidden',
      display: 'block',
      textOverflow: 'ellipsis',
      paddingRight: '0.25rem',
    },
  },
});

export const DeleteIconButton = styled(IconButton)(props => ({
  zIndex: 1,
  position: 'absolute',
  top: '50%',
  transform: 'translate(50%, -50%)',
  right: 'calc(25% - 1rem) ',
  svg: {
    color: props.theme.palette.blue.main,
    width: '1.5rem',
    height: '1.5rem',
  },
}));

export const ButtonWrapper = styled(Box)(props => ({
  width: '100%',
  height: '3.75rem',
  minHeight: '42px',
  borderTop: `1px solid #edeeef `,
  color: props.theme.palette.gray_1.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const PreviewWrap = styled(Box)({
  width: '100%',
  height: '18.25rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  img: {
    maxWidth: '80%',
    maxHeight: '80%',
  },
});

export const ButtonAfterUpload = styled(ButtonContainer)({
  width: '50%',
  height: '100%',
  borderRadius: '0',
  fontSize: '1.2rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&.Mui-disabled': {
    cursor: 'inherit',
  },
});

export const DefaultLogoButton = styled(ButtonContainer)({
  width: '100%',
  height: '100%',
  borderRadius: '0',
  fontSize: '1.2rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&.Mui-disabled': {
    cursor: 'inherit',
  },
});
