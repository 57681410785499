import React from 'react';
import { Modal, Wrapper, Wrap, ModalAlert, Title } from './ManualModalAlert.Styles';

export interface ManualModalAlertVAProps {
  visible: boolean;
  onClose: () => void;
  onClickKaKaoButton: () => void;
}

export const ManualModalAlertUI = React.memo(function ManualModalAlertUI({
  visible,
  onClose,
  onClickKaKaoButton,
}: ManualModalAlertVAProps) {
  return (
    <Modal open={visible} onClose={onClose}>
      <Wrapper>
        <Wrap>
          <ModalAlert>
            <Title>
              구매하신 상품에서는 이용할 수 없습니다.{'\r\n'}
              <span className='text-blue' style={{ fontWeight: '700', cursor: 'pointer' }} onClick={onClickKaKaoButton}>
                {'상품변경 문의하기 >'}
              </span>
            </Title>
          </ModalAlert>
        </Wrap>
      </Wrapper>
    </Modal>
  );
});
