import { atom } from 'recoil';

export interface DeviceType {
  platform: string;
  screen_width: number;
  screen_height: number;
  recognition_available: boolean;
  webkit_speech_available: boolean;
  is_mobile: boolean;
  current_version: string;
  last_version: string;
}

export const deviceState = atom<DeviceType>({
  key: 'deviceState',
  default: {
    platform: '',
    screen_width: 0,
    screen_height: 0,
    recognition_available: false,
    webkit_speech_available: false,
    is_mobile: false,
    current_version: '1.0.0', // 버전 업데이트시 마 변경
    last_version: '1.0.0', // 앱 실행시 업데이트
  },
});
