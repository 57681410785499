import { atom, SetterOrUpdater } from 'recoil';

export interface UploadModalDataType {
  visible: boolean;
  type: string;
  uploaded: boolean;
  imageSize: number;
  imageName: string;
  imageFile: File | null;
  imageUrl: string;
  imageType: string;
  isDefault: boolean;
  uploadingCallback?: () => void;
  closeCallback?: () => void;
}

export const uploadModalData = atom<UploadModalDataType>({
  key: 'uploadModal',
  default: {
    visible: false,
    type: '',
    uploaded: false,
    imageSize: 0,
    imageFile: null,
    imageName: '',
    imageUrl: '',
    imageType: '',
    isDefault: true,
    uploadingCallback: () => {
      return;
    },
    closeCallback: () => {
      return;
    },
  },
});

interface UploadModalSetter {
  setUploadModal: SetterOrUpdater<UploadModalDataType>;
}

export const resetUploadModal = ({ setUploadModal }: UploadModalSetter) => {
  setUploadModal({
    visible: false,
    type: '',
    uploaded: false,
    imageSize: 0,
    imageFile: null,
    imageName: '',
    imageUrl: '',
    imageType: '',
    isDefault: true,
    uploadingCallback: () => {
      return;
    },
    closeCallback: () => {
      return;
    },
  });
};

export const clearUploadModal = ({ setUploadModal }: UploadModalSetter) => {
  setUploadModal(prev => ({
    ...prev,
    uploaded: false,
    imageSize: 0,
    imageFile: null,
    imageName: '',
    imageUrl: '',
    imageType: '',
  }));
};
