import { styled, Box } from '@mui/material';
import { ButtonContainer } from 'components/common/Button/Button.Container';
export { Modal, Wrapper } from 'components/common/ModalAlert/ModalAlert.Styles';

export const Wrap = styled(Box)(props => ({
  maxWidth: '480px',
  width: '35.7rem',
  borderRadius: '4px',
  lineHeight: '2rem',
  backgroundColor: props.theme.palette.white.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const Title = styled(Box)(props => ({
  width: '100%',
  [props.theme.breakpoints.up('xxl')]: {
    fontSize: '1.1rem',
  },
  [props.theme.breakpoints.down('xxl')]: {
    fontSize: '1.2rem',
  },
  whiteSpace: 'pre-wrap',
  textAlign: 'center',
  color: 'rgba(0, 0, 0, 0.87)',
  padding: '32px 16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  '& > span': {
    '&.sub-title': {
      fontSize: '1rem',
      lineHeight: '1.45rem',
    },
  },
}));

export const ButtonWrap = styled(Box)(props => ({
  width: '100%',
  height: '52px',
  borderTop: `1px solid ${props.theme.palette.gray_3.main}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const Button = styled(ButtonContainer)({
  fontSize: '1.075rem',
  width: 'calc(50% - 0.5px)',
  height: '100%',
});

export const Line = styled(Box)(props => ({
  width: '1px',
  height: '100%',
  backgroundColor: props.theme.palette.gray_3.main,
}));
