import { atom, SetterOrUpdater } from 'recoil';

export interface TeacherRowDataType {
  id: number;
  teacher_id: number;
  name: string;
  selected: boolean;
  disabled: boolean;
}

export interface ModalAddTeacherType {
  visible: boolean;
  teacherList: TeacherRowDataType[];
  disableTeacerIds: number[];
  onSuccess: () => void;
}

export const modalAddTeacherData = atom<ModalAddTeacherType>({
  key: 'modalAddTeacher',
  default: {
    visible: false,
    teacherList: [],
    disableTeacerIds: [],
    onSuccess: () => {
      return;
    },
  },
});

interface ModalAddTeacherSetter {
  setModalAddTeacher: SetterOrUpdater<ModalAddTeacherType>;
}

export const openModalAddTeacher = (
  { setModalAddTeacher }: ModalAddTeacherSetter,
  disableTeacerIds?: number[],
  onSuccess?: () => void,
) => {
  setModalAddTeacher(prev => ({
    ...prev,
    visible: true,
    disableTeacerIds: disableTeacerIds ? [...disableTeacerIds] : [],
    onSuccess: onSuccess
      ? onSuccess
      : () => {
          return;
        },
  }));
};
