import { styled, Box } from '@mui/material';

export const Wrapper = styled(Box)(props => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginLeft: '3.3rem',
  width: '100%',
  gap: '2rem',
}));

export const ButtonWrapper = styled(Box)(props => ({
  '& > button': {
    paddingTop: '0.25rem',
    color: props.theme.palette.longvoca.main,
    fontSize: '1.3rem',
    width: '4rem',
    height: '3rem',
    border: 'none',
    fontWeight: '700',
    textAlign: 'center',
    backgroundColor: props.theme.palette.longvoca_3.main,
    borderRadius: '1rem',
    cursor: 'pointer',
  },
}));

export const InputWrapper = styled(Box)(props => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  '& > button': {
    backgroundColor: 'white',
    border: 'none',
    cursor: 'pointer',
  },
  '& > input': {
    paddingTop: '0.25rem',
    color: props.theme.palette.longvoca.main,
    fontSize: '1.3rem !important',
    width: '4rem',
    height: '3rem',
    border: 'none',
    fontWeight: '700',
    textAlign: 'center',
    backgroundColor: props.theme.palette.longvoca_3.main,
    borderRadius: '1rem',
    outline: 'none',
  },
}));
