import { Modal, Wrapper, Wrap, Title, ButtonWrap, Button, Line } from './ModalConfirm.Styles';
import { ModalConfirmDataType } from 'recoil/modalConfirm';
import React from 'react';

export interface ModalConfirmVAProps extends Omit<ModalConfirmDataType, 'onSuccess' | 'onCancel'> {
  onCloseModal: () => void;
  onButtonClick: (text: string) => void;
}

export const ModalConfirmUI = React.memo(function ModalConfirmUI({
  visible,
  title,
  left,
  right,
  onCloseModal,
  onButtonClick,
}: ModalConfirmVAProps) {
  return (
    <Modal open={visible} onClose={onCloseModal}>
      <Wrapper>
        <Wrap>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
          <ButtonWrap>
            <Button color='longvoca' onClick={() => onButtonClick(left)}>
              {left}
            </Button>
            <Line />
            <Button color='gray_1' onClick={() => onButtonClick(right)}>
              {right}
            </Button>
          </ButtonWrap>
        </Wrap>
      </Wrapper>
    </Modal>
  );
});
