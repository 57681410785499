import { styled, Box } from '@mui/material';
import { CalendarPicker as MuiCalendarPicker } from '@mui/x-date-pickers';
export { Modal, Wrapper } from 'components/common/ModalAlert/ModalAlert.Styles';

export const CalendarPicker = styled(MuiCalendarPicker)(({ className, theme, view }) => {
  let year_style = {};
  let month_style = {};
  if (view === 'year') {
    year_style = {
      overflowY: 'auto',
      '& > div': {
        '&:first-of-type': {
          display: 'none',
        },
        '&:last-of-type': {
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '& > div': {
            '& > div': {
              display: 'flex',
              flexDirection: className == 'reservation' ? 'column' : 'column-reverse',
              margin: '0',
              padding: '0',
              maxHeight: 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              '& > div': {
                width: '100%',
                button: {
                  height: '3.25rem',
                  width: '100%',
                  borderRadius: '0',
                  margin: '0',
                  padding: '1rem 0',
                  fontSize: '1.2rem',
                  fontWeight: '500',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '&.Mui-selected': {
                    backgroundColor: '#e0e0e0',
                    color: theme.palette.black.main,
                  },
                },
              },
            },
          },
        },
      },
    };
  } else if (view === 'month') {
    month_style = {
      '& > div': {
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '&:first-of-type': {
          position: 'relative',
          marginTop: '15px',
          '& > div': {
            '&:first-of-type': {
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              position: 'relative',
              right: '0.95rem',
              cursor: 'default',
              '& > div': {
                '&:first-of-type': {
                  textAlign: 'center',
                  '& > div': {
                    marginRight: '0',
                  },
                },
              },

              button: {
                display: 'none',
              },
            },
            '&:last-of-type': {
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0 3rem',
              position: 'absolute',
              top: '50%',
              left: 0,
              transform: 'translateY(-50%)',
            },
          },
        },
        '&:last-of-type': {
          height: 'calc(100% - 30px)',
          '& > div': {
            height: '100%',
            '& > div': {
              height: '100%',
              button: {
                height: '25%',
                margin: '0',
                '&:hover': {
                  borderRadius: '0',
                },
                '&:disabled': {
                  color: 'rgb(204, 204, 204)',
                },
              },
            },
          },
        },
        '.PrivatePickersMonth-root.Mui-selected': {
          backgroundColor: theme.palette.longvoca.main,
          borderRadius: '0',
        },
      },
    };
  }
  return {
    width: '24rem',
    height: '25.714rem',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& > div': {
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      fontSize: '1rem !important',
      // marginTop: '15px',
      '&:first-of-type': {
        position: 'relative',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '& > div': {
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '&:first-of-type': {
            position: 'relative',
            right: '0.95rem',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            width: '100%',
            display: 'flex',
            justifyContent: 'center',

            '& > div': {
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              '&:first-of-type': {
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                textAlign: 'center',
                '& > div': {
                  marginRight: '0',
                },
              },
            },

            button: {
              display: 'none',
            },
          },
          '&:last-of-type': {
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            width: '100%',
            height: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 0.5rem',
            position: 'absolute',
            top: '50%',
            left: 0,
            transform: 'translateY(-50%)',
            '& > button': {
              padding: '0',
              width: '2.5rem',
              height: '2.5rem',
            },
          },
        },
      },

      '.PrivatePickersSlideTransition-root': {
        minHeight: '238px',
        height: '238px',
        '.MuiPickersDay-root': {
          '&.Mui-selected': {
            backgroundColor: theme.palette.longvoca.main,
          },
        },
      },
      '&:last-of-type': {
        height: 'calc(100% - 30px)',
        overflowY: 'auto',
        '& > div': {
          height: '100%',
          '& > div:first-of-type': {
            height: '2.5rem',
            gap: '0.1rem 0rem',
            '& > span': {
              width: '2.5rem',
              height: '2.5rem',
            },
          },
          '& > div:last-of-type': {
            height: '100%',
            '& > div': {
              '&:first-of-type': {
                height: 'calc(2.85714rem)',
                gap: '0.1rem 0rem',
                '& > span': {
                  width: 'calc(3.21429rem)',
                  height: 'calc(2.67857rem)',
                  fontSize: '1rem',
                  margin: '0px',
                },
              },
              '&:last-of-type': {
                height: 'calc(100% - 3rem)',
                minHeight: '238px',
              },
              '& > span': {
                width: '2.375rem',
                height: '2.375rem',
              },
              '& > div': {
                margin: '0',
                gap: '0.1rem 0rem',
                padding: '0 0.75rem',
                '& > div': {
                  gap: '0.1rem 0rem',
                  margin: '0',
                  '& > div': {
                    // width: 'calc(3.21429rem)',
                    width: 'calc(3.75129rem)',
                    height: 'calc(2.67857rem)',
                    padding: '0px calc(0.267857rem)',
                    margin: '0',
                  },
                  '& > button': {
                    width: 'calc(3.21429rem)',
                    height: 'calc(2.67857rem)',
                    fontSize: '1rem',
                    margin: '0px 0.2685rem',
                    '&:disabled': {
                      color: 'rgb(204, 204, 204)',
                    },
                    '&:not(.Mui-disabled, .Mui-selected)': {
                      color: className == 'dailyReport' ? theme.palette.longvoca.main : '',
                      fontWeight: className == 'dailyReport' ? '700' : '',
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    ...year_style,
    ...month_style,
    margin: '0',
  };
});

export const Wrap = styled(Box)(props => ({
  minWidth: '24rem',
  backgroundColor: props.theme.palette.white.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const Title = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '6rem',
  background: 'linear-gradient(#652ae9, #4620e9)',
  color: theme.palette.white.main,
  padding: '0 1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  flexDirection: 'column',
  h2: {
    cursor: 'pointer',
  },
  h4: {
    color: theme.palette.gray_3.main,
  },
}));
