import theme from 'mui/themes';

export const main_fields = [
  { key: 'uname', label: '이름', width: '10.375%', sort: false },
  { key: 'bname', label: '교재명', width: '30.375%', sort: false },
  { key: 'unit', label: '단원', width: '20.375%', sort: false },
  { key: 'score', label: '점수', width: '13.5%', sort: false },
  { key: 'studydate', label: '학습 날짜', width: '13.5%', sort: false },
];

export const free_main_fields = [
  { key: 'uname', label: '이름', width: '17%', sort: false },
  { key: 'bname', label: '교재명', width: '34%', sort: false },
  { key: 'unit', label: '단원', width: '24%', sort: false },
  { key: 'score', label: '점수', width: '15%', sort: false },
];

export const teacher_list_fields = [
  { key: 'permission', label: '구분', width: '16.375%', sort: true, text_color: theme.palette.table_red.main },
  { key: 'name', label: '이름', width: '19.375%', sort: true },
  { key: 'accountId', label: '아이디', width: '19.375%', sort: true },
  { key: 'phone', label: '전화번호', width: '19.375%', sort: true },
  { key: 'postdate', label: '등록일', width: '13%', sort: true },
];

export const class_list_fields = [
  {
    key: 'name',
    label: '클래스',
    /* width: '12.9375%', */ width: '19%',
    sort: true,
    text_color: theme.palette.table_blue.main,
  },
  { key: 'study_days', label: '수업요일', /* width: '15.4375%', */ width: '19%', sort: true },
  { key: 'count', label: '학생수', /* width: '8.9375%', */ width: '12.375%', sort: true },
  { key: 'study_start_time', label: '수업 시작시간', width: '12.375%', sort: true },
  // { key: 'use_push', label: '푸시 알림', width: '8.9375%', sort: true },
  { key: 'use_rank', label: '랭킹 사용', /* width: '8.9375%', */ width: '12.375%', sort: true },
  { key: 'postdate', label: '생성일', /* width: '10.9375%', */ width: '12.375%', sort: true },
];

export const student_list_fields = [
  { key: 'class_name', label: '클래스', width: '16.25%', sort: true },
  { key: 'name', label: '이름', width: '17.5%', sort: true, text_color: theme.palette.table_blue.main },
  { key: 'accountId', label: '아이디', width: '20%', sort: true },
  { key: 'grade', label: '학년', width: '17.5%', sort: true },
  { key: 'postdate', label: '등록일', width: '16.25%', sort: true },
];

export const list_excel_fields = [
  { key: 'class_name', label: '클래스', width: '10%' },
  { key: 'grade', label: '학년', width: '10%' },
  { key: 'name', label: '이름', width: '10%' },
  { key: 'phone', label: '연락처', width: '10%' },
  { key: 'accountId', label: '아이디', width: '10%' },
  { key: 'password', label: '비밀번호', width: '10%' },
  { key: 'school', label: '학교명', width: '10%' },
  { key: 'birthdate', label: '생년월일', width: '10%' },
  { key: 'parent_name', label: '학부모 이름', width: '10%' },
  { key: 'parent_phone', label: '학부모 연락처', width: '10%' },
];

export const real_time_search_fields = [
  { key: 'division', label: '구분', width: '9%', is_padding: '2rem' },
  { key: 'uname', label: '이름', width: '14%' },
  { key: 'bname', label: '교재명', width: '25%' },
  { key: 'unit', label: '단원', width: '16%' },
  { key: 'score', label: '점수', width: '16%' },
  { key: 'studydate', label: '학습 날짜', width: '20%' },
];

export const book_make_table_fields = [
  { key: 'bname', label: '교재명', width: '50%', is_padding: '2rem', sort: true },
  { key: 'wordCount', label: '단어 수', width: '25%', sort: true },
  { key: 'import', label: '가져오기', width: '25%' },
];

export const book_list_fields = [
  { key: 'name', label: '교재명', width: '43.5%', sort: true },
  { key: 'units_count', label: '학습일차', width: '16.5%', sort: true },
  { key: 'words_count', label: '전체 문항', width: '16.5%', sort: true },
  { key: 'postdate', label: '생성일', width: '11%', sort: true },
];

export const test_list_fields = [
  { key: 'testname', label: '테스트명', width: '36.5%', sort: true },
  { key: 'totalCnt', label: '문제수', width: '10%', sort: true },
  { key: 'cutLine', label: '커트라인', width: '10%', sort: true },
  { key: 'examDate', label: '출제일자', width: '12%', sort: true },
  { key: 'submitCnt', label: '제출현황', width: '10%', sort: true },
  { key: 'detail', label: '상세조회', width: '9%', center: true, sort: false, button_color: 'green' },
];

export const daily_report_fields = [
  { key: 'class_name', label: '클래스', width: '10.5%', sort: true },
  { key: 'name', label: '이름', width: '8.5%', sort: true, text_color: theme.palette.table_blue.main },
  { key: 'schedule_name', label: '교재명', width: '21.5%' },
  { key: 'unit_name', label: '단원', width: '21.5%' },
  { key: 'studytime', label: '학습 시간', width: '8.5%', sort: true },
  { key: 'avg', label: '종합 점수', width: '8.5%', text_color: theme.palette.longvoca.main, sort: true },
  { key: 'report_button', label: '레포트 조회', width: '8.5%', center: true },
];

export const period_report_fields = [
  { key: 'class_name', label: '클래스', width: '9.889%', sort: true },
  { key: 'name', label: '이름', width: '9.889%', sort: true, text_color: theme.palette.table_blue.main },
  { key: 'study_count', label: '학습횟수', width: '9.889%' },
  { key: 'total_words_count', label: '학습 문제', width: '9.889%' },
  { key: 'wrong_count', label: '오답 문제', width: '9.889%' },
  { key: 'wrong_rate', label: '오답률', width: '9.889%', sort: true },
  { key: 'studytime', label: '학습 시간', width: '9.889%', sort: true },
  { key: 'avg', label: '종합 점수', width: '9.889%', sort: true, text_color: theme.palette.longvoca.main },
  { key: 'report_button', label: '레포트 조회', width: '9.889%', center: true },
];

export const monthly_report_fields = [
  { key: 'ranking', label: '순위', width: '18.3%', sort: true, is_padding: '3rem' },
  { key: 'class_name', label: '클래스', width: '18.3%' },
  { key: 'name', label: '이름', width: '18.3%', text_color: theme.palette.table_blue.main },
  { key: 'words_count', label: '학습 단어', width: '18.3%' },
  { key: 'score', label: '종합 점수', width: '18.3%' },
  { key: 'award_button', label: '조회', width: '8.5%', center: true },
];

export const accumulated_report_fields = [
  { key: 'ranking', label: '순위', width: '20%', sort: true, is_padding: '3rem' },
  { key: 'class_name', label: '클래스', width: '20%' },
  { key: 'name', label: '이름', width: '20%', text_color: theme.palette.table_blue.main },
  { key: 'words_count', label: '학습 단어', width: '20%' },
  { key: 'score', label: '종합 점수', width: '20%' },
];

export const studying_book_fields = [
  { key: 'book_name', label: '교재명', width: '60%' },
  { key: 'progress', label: '진도율', width: '30%' },
];

export const studied_book_fields = [
  { key: 'book_name', label: '교재명', width: '60%' },
  { key: 'progress', label: '진도율', width: '30%' },
];

export const study_journal_fields = [
  { key: 'type', label: '구분', width: '4.5%' },
  { key: 'book_name', label: '교재명', width: '30%' },
  { key: 'chapter', label: '단원', width: '12.5%' },
  { key: 'studytime', label: '학습 시간', width: '8.5%', sort: true },
  { key: 'study_status', label: '학습 상태', width: '8.5%', sort: true },
  { key: 'studydate', label: '학습일', width: '12.5%', sort: true },
  { key: 'report_button', label: '레포트', width: '10.5%', center: true },
];

export const award_history = [
  { key: 'ranking', label: '순위', width: '20%', sort: true, is_padding: '0.1rem' },
  { key: 'class_name', label: '클래스', width: '20%' },
  { key: 'name', label: '이름', width: '20%', text_color: theme.palette.table_blue.main },
  { key: 'award_count', label: '수상 횟수', width: '20%', sort: true },
];

export const message_detail = [
  { key: 'type', label: '구분', width: '8.5%' },
  { key: 'class_name', label: '클래스', width: '8.2%', is_padding: '0.5rem' },
  {
    key: 'student_name',
    label: '이름',
    width: '8.2%',
    is_padding: '0.5rem',
    text_color: theme.palette.table_blue.main,
  },
  { key: 'book_name', label: '교재명', width: '15.9%', is_padding: '0.5rem' },
  { key: 'unit_name', label: '차시', width: '16.5%' },
  { key: 'comment', label: '코멘트', width: '18.2%' },
  { key: 'senddate', label: '발송일자', width: '11.7%', is_padding: '0.5rem' },
  { key: 'detail_button', label: '조회', width: '6.8%', center: true },
];

export const notice_fields = [
  { key: 'type', label: '항목', width: '14%', sort: true },
  { key: 'title', label: '제목', width: '48%', sort: true },
  { key: 'postdate', label: '등록일', width: '17%', sort: true },
  { key: 'message_button', label: '메시지 발송', width: '8.5%', center: true },
];

export const update_news_fields = [
  { key: 'type', label: '항목', width: '17.5%', sort: true, is_padding: '0.5rem' },
  { key: 'title', label: '제목', width: '55%', sort: true },
  { key: 'modifydate', label: '등록일', width: '20%', sort: true },
];

export const check_usage_fields = [
  { key: 'class', label: '클래스', width: '18.8%', sort: true },
  { key: 'uname', label: '이름', width: '18.8%', sort: true, text_color: theme.palette.table_blue.main },
  { key: 'accountId', label: '아이디', width: '18.8%', sort: true },
  { key: 'usage', label: '사용량', width: '29.6%', sort: false, text_color: theme.palette.table_blue.main },
  {
    key: 'isUse',
    label: '사용여부',
    width: '8%',
    sort: true,
  },
];

export const payment_fee_fields = [
  { key: 'year', label: '연도', width: '11.75%', sort: true },
  { key: 'month', label: '월', width: '11.75%', sort: true },
  { key: 'overUseCount', label: '전월 초과량', width: '11.75%', sort: false },
  { key: 'cost', label: '청구액', width: '11.75%', sort: false },
  {
    key: 'payment',
    label: '결제액',
    width: '11.75%',

    sort: false,
  },
  { key: 'payDay', label: '결제일', width: '11.75%', sort: false },
  { key: 'paymentKind', label: '결제수단', width: '11.75%', sort: false },
  { key: 'evidenceExpenditure', label: '지출증빙', width: '11.75%', center: true, sort: false, button_color: 'green' },
];

export const learning_status_fields = [
  { key: 'no', label: '인원', width: '15%', center: true },
  { key: 'className', label: '클래스', width: '30%', sort: true },
  { key: 'name', label: '이름', width: '30%', sort: true, text_color: theme.palette.table_blue.main },
  { key: 'score', label: '학습 점수', width: '25%', sort: true },
];

export const realtime_inquiry_fields = [
  { key: 'class_name', label: '클래스', width: '10.5%', sort: true },
  { key: 'name', label: '이름', width: '8.5%', sort: true, text_color: theme.palette.table_blue.main },
  { key: 'schedule_name', label: '교재명', width: '30%' },
  { key: 'unit_name', label: '단원', width: '13%' },
  { key: 'studytime', label: '학습 시간', width: '8.5%', sort: true },
  { key: 'avg', label: '종합 점수', width: '8.5%', text_color: theme.palette.table_purple.main, sort: true },
  { key: 'report_button', label: '레포트 조회', width: '8.5%', center: true },
];
