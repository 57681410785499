import { CircularProgressBottom, LoadingBox, CircularProgressTop, LoadingLabel } from './LoadingWithRecoil.Styles';
import { circularProgressClasses, BoxProps } from '@mui/material';

interface LoadingVAProps extends BoxProps {
  visible: boolean;
}

export function LoadingWithRecoilUI({ visible, sx }: LoadingVAProps) {
  return (
    <LoadingBox id='loading-box' sx={sx} style={visible ? {} : { display: 'none' }}>
      <CircularProgressBottom variant='determinate' size={'9rem'} thickness={4} value={100} />
      <CircularProgressTop
        variant='indeterminate'
        disableShrink
        sx={{
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={'9rem'}
        thickness={4}
      />
      <LoadingLabel id='loading_label'>Loading</LoadingLabel>
    </LoadingBox>
  );
}
