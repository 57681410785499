import { InputLabel, Tooltip } from './InputTitle.Styles';
import { SxProps, Theme, Typography, ClickAwayListener, Box } from '@mui/material';
import React, { ReactElement } from 'react';
import { BsFillQuestionCircleFill } from 'react-icons/bs';

export interface InputTitleVAProps {
  title: string;
  required?: boolean;
  sx?: SxProps<Theme>;
  tooltip?: {
    text: ReactElement;
    direction: TooltipDirection;
  };
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InputTitleUI = React.memo(function InputTitleUI({
  title,
  required,
  sx,
  tooltip,
  open,
  setOpen,
}: InputTitleVAProps) {
  return (
    <InputLabel sx={{ ...sx }}>
      {title}
      {required ? (
        <Typography component='span' sx={{ color: '#e9121d' }}>
          &nbsp;*
        </Typography>
      ) : null}
      {tooltip ? (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Box component={'span'} sx={{ ml: '0.25rem', cursor: 'pointer' }}>
            <Tooltip
              title={tooltip.text}
              placement={tooltip.direction}
              open={open}
              onClose={() => setOpen(false)}
              onClick={() => setOpen(true)}
            >
              <Box component={'span'} sx={{ fontSize: '1.182rem', display: 'flex', alignItems: 'center' }}>
                <BsFillQuestionCircleFill />
              </Box>
            </Tooltip>
          </Box>
        </ClickAwayListener>
      ) : null}
    </InputLabel>
  );
});
