import { deleteCookie } from 'utils/cookie';
import { atom, SetterOrUpdater } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { fetchGetApi } from 'utils/api';

const { persistAtom } = recoilPersist({
  key: 'user',
  storage: sessionStorage,
});

export interface Step {
  step: number;
  completed: boolean;
  content: string;
}

export interface MissionType {
  benefit_first_img: string;
  benefit_last_img: string;
  complete_img: string;
  curr_step: number;
  title: string;
  step: Step[];
}

export interface UserInfoType {
  name: string;
  center_id: number;
  center_name: string;
  id: number;
  teacher_id: number;
  type: number;
  pass: boolean;
  phone: string;
  accountId: string;
  product: string;
  product_name: string;
  productStartDate: string;
  productEndDate: string;
  logo: string;
  profile: string;
  logo_type: string;
  profile_type: string;
  messageCount?: number;
  printCount?: number;
  is_promotion?: number;
  is_first: number;
  startdate: string;
  enddate: string;
  admin_stop: number;
  admin_real_stop: boolean;
  product_term: number;
  isBlocked: boolean;
  default_profile: string;
  isStop: boolean;
  isWithDrawal: boolean;
  notice_evidence: number;
  center_type: string;
  mission?: MissionType;
  recomm_code: number;
  isJoinEscape: boolean;
  code: string | null;
}

export const userInfoState = atom<UserInfoType>({
  key: 'userState',
  default: {
    center_id: 0,
    center_name: '',
    id: 0,
    teacher_id: 0,
    type: 0,
    name: '',
    pass: false,
    phone: '',
    product: '',
    product_name: '',
    product_term: 0,
    productStartDate: '',
    productEndDate: '',
    accountId: '',
    logo: '',
    profile: '',
    logo_type: '',
    profile_type: '',
    messageCount: 0,
    printCount: 0,
    is_promotion: 0,
    is_first: 0,
    startdate: '',
    enddate: '',
    admin_stop: 0,
    default_profile: '',
    admin_real_stop: false,
    isBlocked: false,
    mission: undefined,
    isStop: false,
    isWithDrawal: false,
    notice_evidence: 0,
    center_type: '',
    recomm_code: 0,
    isJoinEscape: false,
    code: null,
  },
  effects_UNSTABLE: [persistAtom],
});

interface UserInfoSetter {
  setUserInfo: SetterOrUpdater<UserInfoType>;
}

export const resetUserInfo = ({ setUserInfo }: UserInfoSetter) => {
  setUserInfo({
    center_id: 0,
    teacher_id: 0,
    center_name: '',
    id: 0,
    type: 0,
    name: '',
    pass: false,
    phone: '',
    product: '',
    product_name: '',
    product_term: 0,
    productStartDate: '',
    productEndDate: '',
    accountId: '',
    logo: '',
    profile: '',
    logo_type: '',
    profile_type: '',
    messageCount: 0,
    printCount: 0,
    is_promotion: 0,
    is_first: 0,
    startdate: '',
    enddate: '',
    admin_stop: 0,
    default_profile: '',
    admin_real_stop: false,
    isBlocked: false,
    mission: undefined,
    isStop: false,
    isWithDrawal: false,
    notice_evidence: 0,
    center_type: '',
    recomm_code: 0,
    isJoinEscape: false,
    code: null,
  });
  deleteCookie('authToken');
  deleteCookie('refreshToken');
  localStorage.removeItem('autoToken');
};

interface UserInfoState extends UserInfoSetter {
  userInfo: UserInfoType;
}

export const getMessageCount = async ({ userInfo, setUserInfo }: UserInfoState) => {
  const res = await fetchGetApi(`/customers/${userInfo.center_id}/messages/count`)();
  setUserInfo(prevState => ({ ...prevState, messageCount: res.data }));
};

export const getPrintCount = async ({ userInfo, setUserInfo }: UserInfoState) => {
  const res = await fetchGetApi(`/customers/${userInfo.center_id}/prints/count`)();
  setUserInfo(prevState => ({ ...prevState, printCount: res.data }));
  return res.data;
};
