import { Modal, Wrapper, Wrap, Header, Body, Box } from './ModalDefaultProfileSetting.Styles';
import { ModalDefaultProfileSettingType } from 'recoil/modalDefaultProfileSetting';

export interface ModalDefaultProfileSettingVAProps {
  imgReady: boolean;
  modalDefaultProfileSetting: ModalDefaultProfileSettingType;
  defaultProfileArr: Array<{ id: number; imgSrc: string }>;
  onClickClose: (profile_number: number) => void;
  onClose?: () => void;
}

export function ModalDefaultProfileSettingUI({
  imgReady,
  modalDefaultProfileSetting,
  defaultProfileArr,
  onClickClose,
  onClose,
}: ModalDefaultProfileSettingVAProps) {
  return (
    <>
      {imgReady ? (
        <Modal
          open={modalDefaultProfileSetting.visible}
          onClose={modalDefaultProfileSetting.arbitrary ? onClose : undefined}
        >
          <Wrapper>
            <Wrap>
              <Header>
                <Box component={'span'}>학습 프로필을 선택해주세요!</Box>
              </Header>
              <Body>
                {defaultProfileArr.map((profile, idx) => {
                  return (
                    <Box
                      className='profile-option'
                      key={`default-profile-${profile.id}`}
                      onClick={() => onClickClose(idx + 1)}
                    >
                      <img src={profile.imgSrc} alt='default-profile' />
                    </Box>
                  );
                })}
              </Body>
            </Wrap>
          </Wrapper>
        </Modal>
      ) : null}
    </>
  );
}
