import { FiChevronUp, FiChevronDown } from 'react-icons/fi'; // React 아이콘 import
import { ButtonWrapper, InputWrapper, Wrapper } from './SelectTime.Style';
import { Box } from '@mui/material';
import { Button } from '../ModalRadio/ModalRadio.Styles';

interface SelectTimeProps {
  handleMinuteDecrement: () => void;
  handleMinuteIncrement: () => void;
  handleHourDecrement: () => void;
  handleHourIncrement: () => void;
  handleAmpmChange: () => void;
  handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>, type: string) => void;
  handleMinuteChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleHourChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  ampm: string;
  hour: string;
  minute: string;
}

export const SelectTimeUI = ({
  handleMinuteDecrement,
  handleMinuteIncrement,
  handleHourDecrement,
  handleHourIncrement,
  handleAmpmChange,
  handleKeyDown,
  handleMinuteChange,
  handleHourChange,
  onSubmit,
  ampm,
  hour,
  minute,
}: SelectTimeProps) => {
  return (
    <>
      <Wrapper>
        <ButtonWrapper>
          <button onClick={handleAmpmChange}>{ampm}</button>
        </ButtonWrapper>
        <InputWrapper>
          <button onClick={handleHourIncrement}>
            <FiChevronUp size={30} color='gray' />
          </button>
          <input
            type='text'
            maxLength={2}
            value={hour}
            onChange={handleHourChange}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(event, 'hour')}
          />
          <button onClick={handleHourDecrement}>
            <FiChevronDown size={30} color='gray' />
          </button>
        </InputWrapper>
        <Box
          sx={{
            fontSize: '2rem',
            marginX: '-1rem',
          }}
        >
          :
        </Box>
        <InputWrapper>
          <button onClick={handleMinuteIncrement}>
            <FiChevronUp size={30} color='gray' />
          </button>
          <input
            type='text'
            maxLength={2}
            value={minute}
            onChange={handleMinuteChange}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(event, 'minute')}
          />
          <button onClick={handleMinuteDecrement}>
            <FiChevronDown size={30} color='gray' />
          </button>
        </InputWrapper>
      </Wrapper>

      <Button onClick={onSubmit} disableRipple sx={{ minWidth: '69px', paddingLeft: '0' }}>
        <Box component={'span'} sx={{ color: 'longvoca.main' }}>
          확인
        </Box>
      </Button>
    </>
  );
};
