import { styled, Modal as MuiModal, Box } from '@mui/material';

export const Modal = styled(MuiModal)({
  '& > .MuiBox-root': {
    '&:focus-visible': {
      outline: 'none',
    },
  },
});

export const Wrapper = styled(Box)({
  overflow: 'hidden',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
  boxShadow: 'none',
});

export const Wrap = styled(Box)(props => ({
  maxWidth: '480px',
  width: '35.7rem',
  borderRadius: '4px',
  lineHeight: '2rem',
  backgroundColor: props.theme.palette.white.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const Title = styled(Box)(props => ({
  width: '100%',
  [props.theme.breakpoints.up('xxl')]: {
    fontSize: '1.1rem',
  },
  [props.theme.breakpoints.down('xxl')]: {
    fontSize: '1.2rem',
  },
  whiteSpace: 'pre-wrap',
  textAlign: 'center',
  color: 'rgba(0, 0, 0, 0.87)',
  padding: '32px 16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  '& > span': {
    '&:last-of-type': {
      fontSize: '1.2rem',
      lineHeight: '1.5',
    },
  },
}));

export const ButtonWrap = styled(Box)(props => ({
  width: '100%',
  height: '52px',
  borderTop: `1px solid ${props.theme.palette.gray_3.main}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& > button': {
    width: '100%',
    height: '100%',
    fontSize: '1.2rem',
  },
}));
