import { ModalCheckUI } from './ModalCheck.Presenter';
import { useRecoilState } from 'recoil';
import { modalCheckData, ModalCheckDataType } from 'recoil/modalCheck';
import { useCallback } from 'react';
import React from 'react';

export const ModalCheckContainer = React.memo(function ModalCheckContainer() {
  const [modalCheck, setModalCheck] = useRecoilState<ModalCheckDataType>(modalCheckData);

  const onCloseModal = useCallback(() => {
    setModalCheck({
      visible: false,
      title: '',
    });
  }, []);

  const props_obj = {
    ...modalCheck,
    onCloseModal,
  };

  return <ModalCheckUI {...props_obj} />;
});
