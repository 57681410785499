import { atom, SetterOrUpdater } from 'recoil';

export interface ManualModalAlertDataType {
  visible: boolean;
}

export const manualModalAlertData = atom<ManualModalAlertDataType>({
  key: 'manualModalAlert',
  default: {
    visible: false,
  },
});

interface ManualModalAlertSetter {
  setManualModalAlert: SetterOrUpdater<ManualModalAlertDataType>;
}

export const openManualModalAlert = ({ setManualModalAlert }: ManualModalAlertSetter) => {
  setManualModalAlert({
    visible: true,
  });
};

export const closeManualModalAlert = ({ setManualModalAlert }: ManualModalAlertSetter) => {
  setManualModalAlert({
    visible: false,
  });
};
