import { ModalConfirmUI } from './ModalConfirm.Presenter';
import { useRecoilState } from 'recoil';
import { ModalConfirmDataType, modalConfirmData, closeModalConfirm } from 'recoil/modalConfirm';
import { useCallback } from 'react';
import React from 'react';

export const ModalConfirmContainer = React.memo(function ModalConfirmContainer() {
  const [modalConfirm, setModalConfirm] = useRecoilState<ModalConfirmDataType>(modalConfirmData);

  const onCloseModal = useCallback(() => {
    if (modalConfirm.onCancel) {
      modalConfirm.onCancel();
    }
    closeModalConfirm({ setModalConfirm });
  }, [modalConfirm]);

  const onButtonClick = useCallback(
    (text: string) => {
      const isCancel = ['취소'].includes(text);
      if (isCancel) {
        // 취소 클릭 메서드
        if (modalConfirm.onCancel) {
          modalConfirm.onCancel();
        }
      } else {
        modalConfirm.onSuccess();
        // 확인 클릭 메서드
      }
      closeModalConfirm({ setModalConfirm });
    },
    [modalConfirm],
  );

  const props_obj = {
    ...modalConfirm,
    onCloseModal,
    onButtonClick,
  };

  return <ModalConfirmUI {...props_obj} />;
});
