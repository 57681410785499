import profile_01 from 'assets/images/default-profile/profile_01.png';
import profile_02 from 'assets/images/default-profile/profile_02.png';
import profile_03 from 'assets/images/default-profile/profile_03.png';
import profile_04 from 'assets/images/default-profile/profile_04.png';
import profile_05 from 'assets/images/default-profile/profile_05.png';
import profile_06 from 'assets/images/default-profile/profile_06.png';
import profile_07 from 'assets/images/default-profile/profile_07.png';
import profile_08 from 'assets/images/default-profile/profile_08.png';
import { useMemo, useState, useCallback, useLayoutEffect } from 'react';
import { ModalDefaultProfileSettingUI } from './ModalDefaultProfileSetting.Presenter';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  ModalDefaultProfileSettingType,
  modalDefaultProfileSettingState,
  closeModalDefaultProfileSetting,
} from 'recoil/modalDefaultProfileSetting';
import { ModalAlertDataType, modalAlertData, openModalAlert } from 'recoil/modalAlert';
import { userInfoState, UserInfoType } from 'recoil/userInfo';
import { useMutation } from 'react-query';
import { fetchPostApi } from 'utils/api';

export function ModalDefaultProfileSettingContainer() {
  const setModalAlert = useSetRecoilState<ModalAlertDataType>(modalAlertData);
  const [userInfo, setUserInfo] = useRecoilState<UserInfoType>(userInfoState);
  const [modalDefaultProfileSetting, setModalDefaultProfileSetting] = useRecoilState<ModalDefaultProfileSettingType>(
    modalDefaultProfileSettingState,
  );
  const [imgReady, setImgReady] = useState(false);

  const defaultProfileMutation = useMutation(fetchPostApi);

  const defaultProfileArr = useMemo(() => {
    return [
      { id: 1, imgSrc: profile_01 },
      { id: 2, imgSrc: profile_02 },
      { id: 3, imgSrc: profile_03 },
      { id: 4, imgSrc: profile_04 },
      { id: 5, imgSrc: profile_05 },
      { id: 6, imgSrc: profile_06 },
      { id: 7, imgSrc: profile_07 },
      { id: 8, imgSrc: profile_08 },
    ];
  }, []);

  useLayoutEffect(() => {
    const loadImage = defaultProfileArr.map(profile => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = profile.imgSrc;
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    Promise.all(loadImage).finally(() => {
      setImgReady(true);
    });
  }, []);

  const onClose = useCallback(() => {
    closeModalDefaultProfileSetting({ setModalDefaultProfileSetting });
  }, []);

  const onClickClose = useCallback(
    async (profile_number: number) => {
      try {
        const uri = `/customers/${userInfo.center_id}/accounts/${userInfo.id}/profile/default`;
        const args = {
          default_profile: profile_number,
        };
        const res = await defaultProfileMutation.mutateAsync({ uri, args });

        if (res.result) {
          setUserInfo(prev => ({
            ...prev,
            profile: res.data.profile,
            default_profile: `${profile_number}`,
            profile_type: 'default',
          }));
          closeModalDefaultProfileSetting({ setModalDefaultProfileSetting });
          openModalAlert({ setModalAlert }, 'empty', '학습 프로필이 선택되었습니다.');
        }
      } catch (err) {
        console.log(err);
      }
    },
    [userInfo],
  );

  const props = {
    imgReady,
    modalDefaultProfileSetting,
    defaultProfileArr,
    onClose,
    onClickClose,
  };

  return <ModalDefaultProfileSettingUI {...props} />;
}
