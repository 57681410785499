import { Button as MuiButton, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Button = styled(MuiButton)<ButtonProps>(props => ({
  boxShadow: 'none',
  fontSize: '1rem',
  lineHeight: 1.5,

  '&.regi': {
    minWidth: '8rem',
  },
  '&:hover': {
    boxShadow: 'none',
  },
  '&.disabled': {
    '&.MuiButton-contained': {
      backgroundColor: 'rgb(232, 232, 232) ',
      color: props.theme.palette.white.main,
    },

    '&.MuiButton-outlined': {
      borderColor: '#e8e8e8 ',
    },
  },
  '&.Mui-disabled': {
    '&.MuiButton-contained': {
      backgroundColor: 'rgb(232, 232, 232) ',
      color: props.theme.palette.white.main,
    },

    '&.MuiButton-outlined': {
      borderColor: '#e8e8e8 ',
    },
  },
}));
