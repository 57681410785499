import { atom } from 'recoil';

export interface ModalRadioContentsType {
  id: number | string;
  name: string | JSX.Element;
  selectedDropdownContent?: { id: number | string | null; content: string };
  dropdownContents?: { id: number | string | null; content: string }[];

  // input 관련 옵션
  visibleInput?: boolean;
  placeholder?: string;
  inputValue?: string;
}

export interface ModalRadioDataType {
  id: string;
  visible: boolean;
  title: string;
  subTitle?: string;
  contents: ModalRadioContentsType[];
  oldValue: ModalRadioContentsType;
  curValue: ModalRadioContentsType;
  updateValue: (modalRadioState: ModalRadioDataType) => void;
  clickCancel: () => void;
}

export const modalRadioData = atom<ModalRadioDataType>({
  key: 'modalRadio',
  default: {
    id: '',
    visible: false,
    title: '',
    subTitle: '',
    contents: [],
    oldValue: { id: '', name: '' },
    curValue: { id: '', name: '' },
    updateValue: (modalRadioState: ModalRadioDataType) => {
      return;
    },
    clickCancel: () => {
      return;
    },
  },
});
