export const alert_contents: { [key: string]: { title: string; sub_title?: string } } = {
  no_search_student: { title: '검색결과가 존재하지 않습니다.' },
  personal_product_alert: {
    title: `구매하신 상품에서는 이용할 수 없습니다.\r\n<span id="change-product-inquiry" class="text-blue" style="font-weight: 700; cursor: pointer;">상품변경 문의하기 ></span>`,
  },
  insufficient_message: { title: '잔여 메세지 수가 부족 합니다.' },
  send_message: { title: 'target 메시지가 발송되었습니다.' },
  resend_message: { title: '선택하신 메시지가 재발송되었습니다.' },
  use_three_print: { title: '단 너비에 따라, 내용이 다소 잘릴 수 있습니다.' },
  not_input_korean: { title: 'target에 한글은 입력하실 수 없습니다.' },
  upload_limit: { title: '업로드 가능한 최대 용량은 10MB입니다.' },
  is_beta_service: { title: '베타서비스 종료 후, 사용하실 수 있습니다.' },
  is_ready_award: { title: '상장 조회 기능 준비중에 있습니다.' },
  please_add_class: { title: '클래스 관리에서 클래스를 등록해주세요.' },
  empty: { title: 'target' },
  login: { title: 'target님, 환영합니다.' },
  login_wrong: { title: '아이디 or 비밀번호가 일치하지 않습니다.' },
  login_empty: { title: '모든 정보를 입력해주세요.' },
  duplicate_login_id: { title: '중복된 아이디가 있습니다.' },
  success_add: { title: 'target 신규 등록되었습니다.' },
  success_add2: { title: 'target 등록되었습니다.' },
  success_add3: { title: '신규 target 등록되었습니다.' },
  success_update: { title: 'target 수정되었습니다.' },
  success_delete: { title: 'target 삭제되었습니다.' },
  success_copy: { title: 'target 복사되었습니다.' },
  success_choice: { title: 'target 선택되었습니다.' },
  success_update_status: { title: '학생이 target 되었습니다.' },
  success_move_class: { title: '클래스가 이동 되었습니다.' },
  failure_move_class: { title: '클래스 이동에 실패했습니다.' },
  failure_download_student: { title: '다운로드 가능한 학생 목록이 없습니다.' },
  success_multi_class: { title: '중복 클래스가 수강 되었습니다.' },
  failure_multi_class: { title: '중복 클래스 수강에 실패했습니다.' },
  do_not_search_keyword: { title: '해당 키워드와 관련된 내용이 없습니다.' },
  accept_image_type: { title: '[jpeg, png] 확장자만 업로드가 가능합니다.' },
  success_setting: { title: 'target 설정되었습니다.' },
  success_publication_expediture_evidence: { title: '지출증빙 정보가 등록변경되었습니다.' },
  failed_change_reciept: { title: '등록된 지출증빙 정보는 변경이 불가합니다.' },
  success_set_schedule: { title: '설정하신 교재가 배포되었습니다.' },
  success_set_schedule_2: { title: '중복 교재 제외 후 배포되었습니다.' },
  success_delete_schedule: { title: '스케쥴 교재가 삭제되었습니다.' },
  success_create_folder: { title: '폴더가 생성되었습니다.' },
  success_create_book: { title: '교재를 생성하였습니다. 교재목록을 확인해주세요.' },
  success_save_book: { title: '임시 저장이 완료되었습니다.' },
  success_move_books: { title: '교재가 이동되었습니다.' },
  success_delete_books: { title: '교재가 삭제되었습니다.' },
  success_delete_folder: { title: '폴더가 삭제되었습니다.' },
  do_not_search_result: { title: '검색된 결과가 없습니다.' },
  only_number: { title: '숫자만 입력해주세요.' },
  exceed_question_number: { title: '문제수는 총 단어수보다 적어야합니다.' },
  over_num_teachers: { title: '관리자는 최대 10명까지 등록 가능합니다.' },
  over_num_students: { title: '학생은 최대 3명까지 등록 가능합니다.' },
  success_deduplication: { title: '중복 단어 target개가 삭제되었습니다.' },
  do_not_input_empty_string: { title: '공백으로 검색 하실 수 없습니다.' },
  do_not_input_special_char: { title: '특수문자는 입력 하실 수 없습니다.' },
  do_not_select_diffrent_tag: { title: '같은 태그의 교재만 다중 선택이 가능합니다.' },
  exist_schedule: { title: '스케쥴 배포된 교재는 수정이 불가합니다.\n교재 복사 기능을 이용해 주세요.' },
  incorrect_period: { title: '조회 일자를 정확히 선택해주세요.' },
  wrong_select_date: {
    title: '조회 일자를 정확히 선택해주세요.',
  },
  success_send_certification_number: { title: '입력하신 휴대폰 번호로 인증번호를 발송하였습니다.' },
  failed_send_certification_number: { title: '문의하신 고객님의 정보는 존재하지 않습니다.' },
  certification_time_over: { title: '인증 시간이 종료되었습니다.' },
  failed_certification: { title: '인증번호가 틀렸습니다.' },
  error_post_failed: { title: '오류로 인해 요청이 실패되었습니다.\r\n다시 시도해주세요.' },
  success_cerfication_loginid: {
    title: '고객님의 휴대폰 번호로 로그인 아이디가 전송되었습니다.\r\n확인 후 로그인해주세요.',
  },
  success_cerfication_password: {
    title: '고객님의 휴대폰 번호로 임시 비밀번호가 전송되었습니다.\r\n로그인 후 비밀번호를 변경해주세요.',
  },
  failure_download_board: { title: '다운로드 가능한 레포트 목록이 없습니다.' },
  info_trial_books: { title: '다른 사용자의 수업자료를 무상 공유해드립니다.\r\n배정된 담당자를 통해 문의하세요!' },
  preparing_features: {
    title: '해당 기능은 준비 중입니다.',
  },
  do_not_have_temporary_books: { title: '임시 저장된 글이 없습니다.' },
  delete_temporary_book: { title: '임시 저장 글이 삭제되었습니다.' },
  success_get_temp_book: { title: '불러오기가 완료되었습니다.' },
  study_start_select_time_minute: { title: '30분 단위로만 입력 가능합니다.' },
  study_start_select_time_min_hour: { title: '오전 9시부터 설정 가능합니다.' },
  study_start_select_time_hour: { title: '올바른 시간을 입력해주세요.' },
  left_students: { title: '현재 클래스에 학생이 존재합니다.\r\n모든 학생의 클래스 변경 후 삭제바랍니다.' },
  do_not_print_word_delete: { title: '최소 1개의 단어는 있어야합니다.' },
  none_duplicate_content: {
    title: '중복된 단어가 없습니다.',
  },
  empty_students_in_class: { title: '발송할 대상이 없습니다.' },
  admin_guide: { title: '곧 관리자 가이드가 출시됩니다.' },
  tmp_class: { title: '체험 전용 데이터이므로, 클래스를 등록해주세요.' },
  tmp_student: { title: '체험 전용 학생이므로, 학생을 신규 등록해주세요.' },
  tmp_student2: { title: '체험 학생이므로, 학생을 등록하여 이용해주세요.' },
  tmp_books: { title: '체험 전용 교재로, 필요 교재는 담당자에게 문의해주세요.' },
  tmp_notices: { title: '학생에게 전달하실 공지를 신규 등록해주세요.' },
  tmp_record: { title: '체험 전용 데이터이므로, 학습앱에서 학습해주세요.' },
  tmp_record_2: { title: '체험 성적표이므로, 학생등록 후 메시지 발송해주세요.' },
  tmp_counsel: { title: '체험 전용 데이터이므로, 학생 등록 후 이용해주세요.' },
  tmp_message_history: { title: '체험 전용 내역이므로, 학생 등록 후 이용해주세요.' },
  tmp_first_student: { title: '체험 임시학생은 신청하신 관리자의 비밀번호와 같습니다.' },
  cash_application_completed: { title: '전송된 전용계좌로 납부 부탁드립니다.' },
  rest_print_count: { title: '출력 횟수가 target회 남았습니다.' },
  free_print_exhausted: { title: '무료체험의 출력 이용횟수가 모두 소진되었습니다. (10회)' },
  tmp_studyview_calendar: {
    title: '기간 선택은 정식 가입 이후 가능합니다.',
  },
  tmp_example_data: {
    title: '이해를 돕기 위한 학습결과 예시 데이터입니다.',
  },
  do_not_have_permission: {
    title: '권한이 없습니다.\r\n이전 페이지로 이동합니다.',
  },
  overflow_test_word: {
    title: '출제 가능 문항보다 선택 문항이 많습니다.',
  },
  do_not_three_print: { title: '서술형 유형에서는 3단으로 설정 불가합니다.' },
  do_not_test_word_delete: { title: '총 단어 수가 문제수보다 작을 수 없습니다.' },
  payment_kind_card: { title: '결제 수단이 카드일 경우,\r\n카드사를 통해 지출증빙됩니다.' },
  expenditure_evidence_text: { title: '최초 결제 이후의 청구건은\r\n기입한 정보로 지출증빙 처리됩니다.' },
  delete_reservation_deployment: { title: '예약 스케쥴이 삭제되었습니다.' },
  update_reservation_deployment: { title: '예약 스케쥴이 변경되었습니다.' },
  max_students_creation_count: { title: '학습 아이디는 최대 3개까지 생성할 수 있습니다.' },
};
