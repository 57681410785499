import {
  LoadingLogoWrap,
  Logo,
  LoadingBox,
  CircularProgressBottom,
  CircularProgressTop,
  LoadingLabel,
} from './Loading.Styles';
import { circularProgressClasses, BoxProps } from '@mui/material';
import longeduLogo from 'assets/images/longedu_bi.png';

export interface LoadingVAProps extends BoxProps {
  isLogo?: boolean;
}

export function LoadingUI({ isLogo, sx }: LoadingVAProps) {
  return (
    <>
      {isLogo ? (
        <LoadingLogoWrap>
          <Logo src={longeduLogo} />
        </LoadingLogoWrap>
      ) : (
        <LoadingBox sx={sx}>
          <CircularProgressBottom variant='determinate' size={'9rem'} thickness={4} value={100} />
          <CircularProgressTop
            variant='indeterminate'
            disableShrink
            sx={{
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: 'round',
              },
            }}
            size={'9rem'}
            thickness={4}
          />
          <LoadingLabel id='loading_label'>Loading</LoadingLabel>
        </LoadingBox>
      )}
    </>
  );
}
