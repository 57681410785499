import { styled, Box } from '@mui/material';
export {
  Modal,
  Wrapper,
  FormControl,
  FormLabel,
  Button,
  SubTitle,
} from 'components/common/ModalRadio/ModalRadio.Styles';

export const Row = styled(Box)({
  width: '100%',
  color: 'var(--gray-1)',
  marginTop: '0.25rem',
  display: 'flex',
  alignItems: 'center',
  paddingRight: '1.5rem',
  padding: '7px 0',
  '& > div': {
    fontSize: '1.125rem',
    '&:first-of-type': {
      marginLeft: '1rem',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      marginRight: 'auto',
    },
    '&:last-of-type': {
      marginRight: '1rem',
      paddingRight: '7px',
    },
  },
});
