import { ModalUpAndDownUI } from './ModalUpAndDown.Presenter';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import {
  ModalUpAndDownContentsType,
  ModalUpAndDownDataType,
  modalUpAndDownData,
  closeModalUpAndDown,
} from 'recoil/modalUpAndDown';

export function ModalUpAndDownContainer() {
  const [modalUpAndDown, setModalUpAndDown] = useRecoilState<ModalUpAndDownDataType>(modalUpAndDownData);
  const [contents, setContents] = useState<ModalUpAndDownContentsType[]>([]);

  useEffect(() => {
    if (modalUpAndDown.visible) {
      const new_contents = modalUpAndDown.contents.map(content => ({ ...content }));
      setContents(new_contents);
    } else {
      setContents([]);
    }
  }, [modalUpAndDown.visible]);

  const checkValue = useMemo(() => {
    let return_value = false;
    for (let i = 0; i < modalUpAndDown.contents.length; i++) {
      if (modalUpAndDown.contents.length && contents.length) {
        const originContent = modalUpAndDown.contents[i];
        const new_content = contents[i];
        if (originContent.value !== new_content.value) {
          return_value = true;
          break;
        }
      }
    }
    return return_value;
  }, [modalUpAndDown, contents]);

  const onClose = useCallback(() => {
    closeModalUpAndDown({ setModalUpAndDown });
  }, []);

  const onClickVariationButton = useCallback(
    (content_idx: number, isUpDown: 'up' | 'down') => {
      const new_contents = contents.map((content, cIdx) => {
        if (cIdx === content_idx) {
          let value = 0;
          let value_idx = content.value_idx;
          if (isUpDown == 'up') {
            value = content.selctableValues[++value_idx];
          } else {
            // down
            value = content.selctableValues[--value_idx];
          }
          return {
            ...content,
            value,
            value_idx,
          };
        } else {
          return { ...content };
        }
      });
      setContents(new_contents);
    },
    [contents],
  );

  const onClickButton = useCallback(() => {
    if (checkValue) {
      // 확인
      modalUpAndDown.updateValue(modalUpAndDown, contents);
    } else {
      // 취소
      modalUpAndDown.clickCancel();
    }
    onClose();
  }, [modalUpAndDown, checkValue, contents]);

  const props_obj = {
    visible: modalUpAndDown.visible,
    title: modalUpAndDown.title,
    subTitle: modalUpAndDown.subTitle,
    contents,
    checkValue,
    onClose,
    onClickVariationButton,
    onClickButton,
  };

  return <ModalUpAndDownUI {...props_obj} />;
}
