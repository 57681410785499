import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    boxShadow: {
      contrastText: '#fff',
      main: '#00000026 0 2.5px 10px',
    },
    longvoca: {
      contrastText: '#fff',
      main: '#4620e9',
    },
    longvoca_2: {
      contrastText: '#fff',
      main: '#6b53f4',
    },
    longvoca_3: {
      contrastText: '#4620e9',
      main: '#f4f4ff',
    },
    longvoca_4: {
      contrastText: '#fff',
      main: '#6b20e9',
    },
    popbill: {
      contrastText: '#2196f3',
      main: 'rgb(220, 248, 252)',
    },
    card_blue: {
      contrastText: '#fff',
      main: '#6b90d2',
    },
    red: {
      contrastText: '#fff',
      main: '#e9121d',
    },
    red_2: {
      contrastText: '#fff',
      main: '#f44336',
    },
    green: {
      contrastText: '#fff',
      main: '#1bd05b',
    },
    green_2: {
      contrastText: '#fff',
      main: '#3dd477',
    },
    green_3: {
      contrastText: '#3dd477',
      main: '#effdf4',
    },
    light_yellow_green: {
      contrastText: '#fff',
      main: '#f7f9e0',
    },
    yellow_green: {
      contrastText: '#fff',
      main: '#32ea88',
    },
    turquoise: {
      contrastText: '#fff',
      main: '#2BD1B1',
    },
    light_orange: {
      contrastText: '#fff',
      main: '#ffafa4',
    },
    orange: {
      contrastText: '#fff',
      main: '#fc521a',
    },
    orange_2: {
      contrastText: '#fff',
      main: '#fcc100',
    },
    orange_3: {
      contrastText: '#fff',
      main: '#ffa025',
    },
    orange_4: {
      contrastText: '#fff',
      main: '#ffa139',
    },
    orange_5: {
      contrastText: '#ffa139',
      main: '#effdf4',
    },
    deep_orange: {
      contrastText: '#fff',
      main: '#ff6c59',
    },
    light_yellow: {
      contrastText: '#fff',
      main: '#fff9ea',
    },
    light_yellow_2: {
      contrastText: '#fff',
      main: '#fff3d6',
    },
    yellow_1: {
      contrastText: '#fff',
      main: '#fcbe00',
    },
    yellow_2: {
      contrastText: '#fff',
      main: '#ffe100',
    },
    yellow_3: {
      contrastText: '#000',
      main: '#FAE100',
    },
    yellow_4: {
      contrastText: '#fff',
      main: '#ffd600',
    },
    yellow_5: {
      contrastText: '#000',
      main: '#ffeb3b',
    },
    yellow_6: {
      contrastText: '#000',
      main: '#ffeb34',
    },
    yellow_7: {
      contrastText: '#fff',
      main: '#ffd91a',
    },
    dark_blue: {
      contrastText: '#fff',
      main: '#b2c7da',
    },
    blue: {
      contrastText: '#fff',
      main: '#00bfff',
    },
    blue_2: {
      contrastText: '#fff',
      main: '#13afff',
    },
    blue_3: {
      contrastText: '#13afff',
      main: '#eef9ff',
    },
    deep_blue: {
      contrastText: '#fff',
      main: '#2f6bc1',
    },
    sky_blue: {
      contrastText: '#fff',
      main: '#e3f8ff',
    },
    deep_sky_blue: {
      contrastText: '#fff',
      main: '#71c5ff',
    },
    purple: {
      contrastText: '#fff',
      main: '#BF5EFB',
    },
    gray: {
      contrastText: '#fff',
      main: '#9f9f9f',
    },
    light_gray: {
      contrastText: '#999',
      main: '#f5f5f5',
    },
    gray_1: {
      contrastText: '#fff',
      main: '#9f9f9f',
    },
    gray_2: {
      contrastText: '#fff',
      main: '#dbdbdb',
    },
    gray_3: {
      contrastText: '#fff',
      main: '#ebebeb',
    },
    gray_4: {
      contrastText: '#000',
      main: '#f0f0f0',
    },
    gray_5: {
      contrastText: '#fff',
      main: '#f8f9fa',
    },
    gray_6: {
      contrastText: '#fff',
      main: '#b7b7b7',
    },
    gray_7: {
      contrastText: '#fff',
      main: '#ddd',
    },
    gray_8: {
      contrastText: '#fff',
      main: '#cbcbcb',
    },
    gray_black: {
      contrastText: '#9f9f9f',
      main: '#e8e8e8',
    },
    gray_off: {
      contrastText: '#fff',
      main: '#c8c8c8',
    },
    white: {
      contrastText: '#000',
      main: '#fff',
    },
    white_2: {
      contrastText: '#000',
      main: '#f5fbff',
    },
    white_3: {
      contrastText: '#000',
      main: '#f6f6f6',
    },
    white_4: {
      contrastText: '#000',
      main: '#fafcff',
    },
    dark: {
      contrastText: '#fff',
      main: '#000',
    },
    black: {
      contrastText: '#fff',
      main: '#000',
    },
    black_2: {
      contrastText: '#fff',
      main: '#404040',
    },
    black_3: {
      contrastText: '#fff',
      main: '#495057',
    },
    black_4: {
      contrastText: '#fff',
      main: '#777',
    },
    black_5: {
      contrastText: '#fff',
      main: '#2d2e30',
    },
    black_6: {
      contrastText: '#fff',
      main: '#323232',
    },
    cyan: {
      contrastText: '#fff',
      main: '#2baed1',
    },
    cyan_2: {
      contrastText: '#fff',
      main: '#4fb9c0',
    },
    light_pink: {
      contrastText: '#000',
      main: '#fff3f1',
    },
    pink: {
      contrastText: '#fff',
      main: '#ff6060',
    },
    brown: {
      contrastText: '#000',
      main: '#736b16',
    },
    navy: {
      contrastText: '#FFF',
      main: '#1a2a3d',
    },
    manager_green: {
      contrastText: '#FFF',
      main: '#0fdc61',
    },
    manager_yellow: {
      contrastText: '#FFF',
      main: '#ffc520',
    },
    manager_blue: {
      contrastText: '#FFF',
      main: '#29d0ff',
    },
    manager_red: {
      contrastText: '#FFF',
      main: '#ff382a',
    },
    manual_blue: {
      contrastText: '#fff',
      main: '#1d87cf',
    },
    account_sky_blue: {
      contrastText: '#000',
      main: '#e9f2fa',
    },
    account_blue: {
      contrastText: '#fff',
      main: '#3579c5',
    },
    table_blue: {
      contrastText: '#fff',
      main: '#2b8ad1',
    },
    table_red: {
      contrastText: '#fff',
      main: '#FF65BD',
    },
    table_purple: {
      contrastText: '#fff',
      main: '#6d15ff',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 960,
      xl: 1264,
      xxl: 1904,
    },
  },
  spacing: 4,
});

export default theme;
