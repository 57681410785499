interface CookieOptions {
  path: string;
  domain: string;
  expires: Date | string;
  'max-age': number;
  samesite: string;
  secure: boolean;
  httpOnly: true;
}

export const getCookie = (name: string): string | undefined => {
  const matches = document.cookie.match(
    new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (name: string, value: string, options: Partial<CookieOptions> = {}): void => {
  // 기본값
  options = {
    path: '/',
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (const optionKey in options) {
    updatedCookie += '; ' + optionKey;
    const optionValue = options[optionKey as keyof CookieOptions];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }

  document.cookie = updatedCookie;
};

export const deleteCookie = (name: string): void => {
  setCookie(name, '', { 'max-age': -1 });
};

export const getTodayEndExipires = (): Date => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const date = today.getDate();
  return new Date(Date.UTC(year, month, date, 23, 59, 59));
};

export const getNextWeekEndExipires = (): Date => {
  const today = new Date(new Date().setTime(new Date().getTime() + 1000 * 60 * 60 * 24 * 6));
  const year = today.getFullYear();
  const month = today.getMonth();
  const date = today.getDate();
  return new Date(Date.UTC(year, month, date, 23, 59, 59));
};
