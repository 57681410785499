import React from 'react';
import { Modal, Wrapper, Wrap, ModalAlert, Title } from './ModalAlert.Styles';

export interface ModalAlertVAProps {
  visible: boolean;
  title: string;
}

export const ModalAlertUI = React.memo(function ModalAlertUI({ visible, title }: ModalAlertVAProps) {
  return (
    <Modal open={visible}>
      <Wrapper>
        <Wrap>
          <ModalAlert>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
          </ModalAlert>
        </Wrap>
      </Wrapper>
    </Modal>
  );
});
