import { atom, SetterOrUpdater } from 'recoil';

export interface ModalDefaultProfileSettingType {
  visible: boolean;
  arbitrary: boolean;
}

export const modalDefaultProfileSettingState = atom<ModalDefaultProfileSettingType>({
  key: 'modalDefaultProfileSetting',
  default: {
    visible: false,
    arbitrary: false,
  },
});

interface ModalDefaultProfileSettingSetter {
  setModalDefaultProfileSetting: SetterOrUpdater<ModalDefaultProfileSettingType>;
  arbitrary?: boolean;
}

export const openModalDefaultProfileSetting = ({
  setModalDefaultProfileSetting,
  arbitrary,
}: ModalDefaultProfileSettingSetter) => {
  const is_arbitrary = arbitrary ? true : false;
  setModalDefaultProfileSetting(prev => ({
    ...prev,
    visible: true,
    arbitrary: is_arbitrary,
  }));
};

export const closeModalDefaultProfileSetting = ({
  setModalDefaultProfileSetting,
}: ModalDefaultProfileSettingSetter) => {
  setModalDefaultProfileSetting({
    visible: false,
    arbitrary: false,
  });
};
