export const check_contents: { [key: string]: { title: string; sub_title?: string; target?: string } } = {
  duplicate_ID: { title: '중복된 아이디가 있습니다.' },
  duplicate_class_name: { title: '중복된 클래스명이 있습니다.' },
  success_send_inquiry: { title: '문의가 접수되었습니다.\n담당자 통해 곧 연략드리겠습니다.' },
  do_not_have_history_for_publication: {
    title: '발행 가능한 내역이 없습니다.',
    sub_title: '<span class="text-gray-1">(현금영수증은 최근 5일 이내 결제건만 발행 가능)</span>',
  },
  do_not_set_no_module: {
    title: '<div>최소 1개 이상 학습 단계가 필요합니다.</div><div>더 이상 해제할 수 없습니다.</div>',
  },
  do_not_set_basic_module: {
    title: '<div>필수 학습인 플래시 카드 포함</div><div>최소 2개 이상 학습 단계가 필요합니다.</div>',
  },
  required_study_module: {
    title: 'target 필수 학습단계입니다.',
  },
  required_input_bookname: {
    title: '교재명을 입력해주세요.',
  },
};
