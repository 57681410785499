import React, { useEffect, useState } from 'react';

import { ModalRadioDataType, modalRadioData } from 'recoil/modalRadio';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { ModalAlertDataType, modalAlertData, openModalAlert } from 'recoil/modalAlert';
import { SelectTimeUI } from './SelectTime.Presenter';

export const SelectTimeContainer = () => {
  const [modalRadioState, setModalRadioState] = useRecoilState<ModalRadioDataType>(modalRadioData);
  const setModalAlert = useSetRecoilState<ModalAlertDataType>(modalAlertData);

  const [ampm, setAmpm] = useState('PM');
  const [hour, setHour] = useState('');
  const [minute, setMinute] = useState('');
  const [startDate, setStartDate] = useState(''); // 09시 00분, 10시 00분

  useEffect(() => {
    if (modalRadioState.curValue.name && typeof modalRadioState.curValue.name == 'string') {
      // 선택되어 있을 경우
      const curValue = modalRadioState.curValue.name.split('시 ');
      const curHour = parseInt(curValue[0]);
      const curMinute = parseInt(curValue[1].split('분')[0].trim());

      // setHour(curHour > 12 ? (curHour - 12).toString() : curHour === 0 ? '12' : curHour.toString());
      setHour(String(curHour));
      setMinute(curMinute.toString().padStart(2, '0'));
      setAmpm(curHour === 0 ? 'AM' : curHour >= 12 ? 'PM' : 'AM');
    } else if (
      !modalRadioState.curValue.name &&
      modalRadioState.oldValue.name &&
      typeof modalRadioState.oldValue.name == 'string'
    ) {
      // 수정시
      const oldValue = modalRadioState.oldValue.name.split('시 ');
      const curHour = parseInt(oldValue[0]);
      const curMinute = parseInt(oldValue[1].split('분')[0].trim());

      // setHour(curHour > 12 ? (curHour - 12).toString() : curHour === 0 ? '12' : curHour.toString());
      setHour(String(curHour));
      setMinute(curMinute.toString().padStart(2, '0'));
      setAmpm(curHour === 0 ? 'AM' : curHour >= 12 ? 'PM' : 'AM');
    } else {
      // 처음 등록시
      setHour('13');
      setMinute('00');
      setAmpm('PM');
    }
  }, []);

  const onSubmit = () => {
    if (minute !== '00' && minute !== '30') {
      openModalAlert({ setModalAlert }, 'study_start_select_time_minute');
      setMinute(prev => (Number(prev) > 30 ? '30' : '00'));
    } else if (
      (ampm === 'AM' && parseInt(hour) > 0 && parseInt(hour) < 9) ||
      (ampm === 'AM' && hour === '12' && minute === '30')
    ) {
      openModalAlert({ setModalAlert }, 'study_start_select_time_min_hour');
    } else {
      modalRadioState.updateValue(modalRadioState);
      setModalRadioState(prev => ({ ...prev, visible: false }));
    }
  };

  useEffect(() => {
    setModalRadioState({
      ...modalRadioState,
      curValue: { id: Number(hour), name: startDate },
    });
  }, [startDate]);

  useEffect(() => {
    const formatTime = (ampm: string, hour: string, minute: string) => {
      const paddedHour = hour.padStart(2, '0');
      const paddedMinute = minute.padStart(2, '0');

      setStartDate(`${paddedHour}시 ${paddedMinute}분`);
    };
    formatTime(ampm, hour, minute);
  }, [ampm, hour, minute]);

  const handleHourChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newHour = parseInt(event.target.value);
    if (newHour < 12) {
      setAmpm('AM');
    } else if (newHour < 24) {
      setAmpm('PM');
    }
    if (event.target.value === '') {
      setHour('');
    } else if (!isNaN(newHour) && newHour >= 0 && newHour <= 23) {
      setHour(String(newHour));
    } else {
      openModalAlert({ setModalAlert }, 'study_start_select_time_hour');
    }
  };

  const handleMinuteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newMinute = event.target.value;

    if (event.target.value === '') {
      setMinute('');
    } else if (newMinute === '0' || newMinute === '00' || (parseInt(newMinute) >= 0 && parseInt(newMinute) <= 59)) {
      setMinute(newMinute);
    } else {
      openModalAlert({ setModalAlert }, 'study_start_select_time_hour');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, type: string) => {
    if (event.key === 'Enter') {
      onSubmit();
    }

    if (event.key === 'ArrowUp') {
      if (type === 'hour') {
        handleHourIncrement();
      } else {
        handleMinuteIncrement();
      }
    }

    if (event.key === 'ArrowDown') {
      if (type === 'hour') {
        handleHourDecrement();
      } else {
        handleMinuteDecrement();
      }
    }
  };

  const handleAmpmChange = () => {
    if (ampm === 'AM' && parseInt(hour) >= 0 && parseInt(hour) < 12) {
      setAmpm('PM');
      setHour(prev => {
        const newHour = parseInt(prev) + 12;
        return String(newHour);
      });
    } else if (ampm === 'PM' && parseInt(hour) >= 12 && parseInt(hour) < 24) {
      setAmpm('AM');
      setHour(prev => {
        const newHour = parseInt(prev) - 12;
        return String(newHour);
      });
    }
  };

  const handleHourIncrement = () => {
    if (hour === '') {
      setHour('0');
    } else if (hour === '11') {
      setAmpm('PM');
    } else if (hour === '23') {
      setAmpm('AM');
    }

    setHour(prevHour => {
      const newHour = parseInt(prevHour) + 1;
      return String(newHour > 23 ? 0 : newHour);
    });
  };

  const handleHourDecrement = () => {
    if (hour === '0') {
      setAmpm('PM');
      setHour('23');
      return;
    } else if (hour === '') {
      setHour('0');
    } else if (hour === '12') {
      setAmpm('AM');
    }

    setHour(prevHour => {
      const newHour = parseInt(prevHour) - 1;
      return String(newHour === -1 ? 23 : newHour);
    });
  };

  const handleMinuteIncrement = () => {
    if (minute === '') {
      setMinute('00');
    } else if (hour === '11' && minute === '30') {
      setAmpm('PM');
    } else if (hour === '23' && minute === '30') {
      setAmpm('AM');
    }

    setMinute(prevMinute => {
      const newMinute = parseInt(prevMinute) + 30;
      if (newMinute >= 60) {
        setHour(prevHour => {
          const newHour = parseInt(prevHour) + 1;
          return String(newHour > 23 ? 0 : newHour);
        });
        return '00';
      } else if (newMinute >= 30) {
        return '30';
      } else {
        return '00';
      }
    });
  };

  const handleMinuteDecrement = () => {
    if (minute === '') {
      setMinute('00');
    } else if (hour === '0' && minute === '00') {
      setMinute('30');
      setHour('23');
      setAmpm('PM');
    } else if (hour === '12' && minute === '00') {
      setMinute('30');
      setHour('11');
      setAmpm('AM');
    } else {
      setMinute(prevMinute => {
        const minute = parseInt(prevMinute);
        if (minute > 30 && minute < 60) {
          // 31 ~ 59분일 경우
          return '30';
        } else if (minute > 0 && minute < 30) {
          return '00'; // 1 ~ 29분일 경우
        } else {
          const newMinute = minute - 30;
          if (newMinute < 0) {
            setHour(prevHour => {
              const newHour = parseInt(prevHour) - 1;
              return String(newHour === -1 ? 23 : newHour);
            });
            return '30'; // 입력이 없는 경우
          }
          return newMinute < 10 ? '0' + newMinute : String(newMinute);
        }
      });
    }
  };

  const SelectTimeProps = {
    handleMinuteDecrement,
    handleMinuteIncrement,
    handleHourDecrement,
    handleHourIncrement,
    handleAmpmChange,
    handleKeyDown,
    handleMinuteChange,
    handleHourChange,
    onSubmit,
    ampm,
    hour,
    minute,
  };

  return <SelectTimeUI {...SelectTimeProps} />;
};
