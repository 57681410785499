import { Modal, Wrapper, FormControl, FormLabel, SubTitle, Button, Row } from './ModalUpAndDown.Styles';
import { Box } from '@mui/material';
import { FaChevronCircleUp, FaChevronCircleDown } from 'react-icons/fa';
import { ModalUpAndDownContentsType } from 'recoil/modalUpAndDown';
import React from 'react';
import theme from 'mui/themes';

export interface ModalUpAndDownVAProps {
  visible: boolean;
  onClose: () => void;
  title: string;
  subTitle?: string;
  contents: ModalUpAndDownContentsType[];
  checkValue: boolean;
  onClickVariationButton: (content_idx: number, isUpDown: 'up' | 'down') => void;
  onClickButton: () => void;
}

export const ModalUpAndDownUI = React.memo(function ModalUpAndDownUI({
  visible,
  title,
  subTitle,
  contents,
  checkValue,
  onClose,
  onClickVariationButton,
  onClickButton,
}: ModalUpAndDownVAProps) {
  return (
    <Modal open={visible} onClose={onClose}>
      <Wrapper sx={{ boxShadow: 24 }}>
        <FormControl>
          <FormLabel>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', userSelect: 'none' }}>
              {title}
              <SubTitle subtitle={subTitle}>{subTitle}</SubTitle>
            </Box>
          </FormLabel>
          {contents.map((content, cIdx) => (
            <Row key={`content_${cIdx}`}>
              <Box sx={{ userSelect: 'none' }}>{content.label}</Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '2rem', svg: { cursor: 'pointer' } }}>
                <FaChevronCircleDown
                  size={'1.45rem'}
                  color={content.value_idx === 0 ? '#e8e8e8' : '#efd6ff'}
                  onClick={content.value_idx === 0 ? undefined : () => onClickVariationButton(cIdx, 'down')}
                />
                <Box
                  component={'span'}
                  sx={{
                    color: theme.palette.longvoca.main,
                    fontWeight: 'bold',
                    userSelect: 'none',
                    width: '2.75rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {content.value}
                  {content.kor_unit}
                </Box>
                <FaChevronCircleUp
                  size={'1.45rem'}
                  color={content.value_idx === content.selctableValues.length - 1 ? '#e8e8e8' : '#efd6ff'}
                  onClick={
                    content.value_idx === content.selctableValues.length - 1
                      ? undefined
                      : () => onClickVariationButton(cIdx, 'up')
                  }
                />
              </Box>
            </Row>
          ))}
          <Button disableRipple onClick={onClickButton}>
            {checkValue ? (
              <Box component={'span'} sx={{ color: 'green.main' }}>
                확인
              </Box>
            ) : (
              <Box component={'span'} sx={{ color: 'black.main' }}>
                취소
              </Box>
            )}
          </Button>
        </FormControl>
      </Wrapper>
    </Modal>
  );
});
