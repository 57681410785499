import {
  styled,
  Box,
  Modal as MuiModal,
  FormControl as MuiFormControl,
  FormLabel as MuiFormLabel,
  RadioGroup as MuiRadioGroup,
  FormControlLabel as MuiFormControlLabel,
  Radio as MuiRadio,
} from '@mui/material';
import { ButtonContainer } from 'components/common/Button/Button.Container';

export const Modal = styled(MuiModal)({
  '& > .MuiBox-root': {
    '&:focus-visible': {
      outline: 'none',
    },
  },
});

export const Wrapper = styled(Box)({
  width: '29rem',
  height: 'auto',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  input: {
    margin: '0',
    fontSize: '1.1rem !important',
  },
});

export const FormControl = styled(MuiFormControl)({
  borderRadius: '8px',
  padding: '2.5rem 1.5rem 1.25rem 2.5rem',
  width: '100%',
  maxWidth: '500px',
  margin: 'auto',
  backgroundColor: 'var(--white)',
  '&:focus-visible': {
    outline: 'none',
  },
});

export const FormLabel = styled(MuiFormLabel)({
  color: 'var(--black)',
  fontSize: '1.125rem',
  marginBottom: '0.75em',
  fontWeight: '700',
  '&.Mui-focused': {
    color: 'var(--black)',
  },
});

export const SubTitle = styled(Box)<{ subtitle?: string; radio_id?: string }>(props => {
  let isBlack = false;
  if (props.subtitle) {
    isBlack = ['(청구일 포함)', '(매년 1월 1일 자동 상향)', '(최근 6개월)', '(순서 랜덤)'].includes(props.subtitle);
  }

  if (props.radio_id == 'temporary_books') {
    return {
      color: props.theme.palette.longvoca.main,
      marginLeft: '0.285rem',
    };
  }

  return {
    fontWeight: '500',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: isBlack ? 'var(--black)' : 'var(--gray-2)',
    marginLeft: '0.25rem',
  };
});

export const RadioGroup = styled(MuiRadioGroup)({
  paddingRight: '2.125rem',
  width: '100%',
  maxHeight: '220px',
  overflowX: 'hidden',
  overflowY: 'auto',
  display: 'block',
  button: {
    marginTop: '0.125rem',
    minHeight: '2.5rem',
    marginRight: '1rem',
    marginLeft: '1rem',
    width: '97%',
  },
});

export const FormControlLabel = styled(MuiFormControlLabel)({
  width: '100%',
  color: 'var(--gray-1)',
  marginTop: '0.25rem',
  minHeight: '2.5rem',
  maxHeight: '2.5rem',
  '&.MuiFormControlLabel-root': {
    marginRight: '1rem',
    marginLeft: '1rem',
  },
  '& div.MuiInputBase-root.MuiOutlinedInput-root': {
    marginBottom: '0 !important',
  },
  '& .MuiTypography-root': {
    fontSize: '1.125rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginRight: 'auto',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      '& > span:first-of-type': {
        width: '20%',
      },
      '& > span:last-of-type': {
        fontSize: '0.85rem',
      },
    },
  },

  '.disabled_box': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

export const Radio = styled(MuiRadio)({
  '&.MuiRadio-root': {
    padding: '7px',
  },
  '& .MuiSvgIcon-root': {
    color: 'var(--gray-1)',
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      color: 'var(--green)',
    },
  },
});

export const Button = styled(ButtonContainer)({
  marginRight: 'calc(1.215rem - (64px / 4) + 2px)',
  marginLeft: 'auto',
  fontSize: '1.125rem',
  marginTop: '0.75rem',
  alignItems: 'start',
  justifyContent: 'center',
  width: '10%',
  height: '2.5rem',
  transition: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
  },

  '&.scroll': {
    marginRight: 'calc(1.215rem - (64px / 4) + 8px)',
  },
});
