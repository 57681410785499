import {
  Modal,
  Wrapper,
  LogoWrap,
  Top,
  Notice,
  FileWrap,
  FileIcon,
  TextField,
  FileButton,
  DeleteIconButton,
  ButtonWrapper,
  ButtonAfterUpload,
  DefaultLogoButton,
  PreviewWrap,
} from './UploadModal.Styles';
import { Box, Typography } from '@mui/material';
import { CgClose } from 'react-icons/cg';
import { UploadModalDataType } from 'recoil/uploadModal';
import { RefObject } from 'react';
import React from 'react';

export interface UploadModalVAProps extends UploadModalDataType {
  domLabel: RefObject<HTMLLabelElement>;
  onClose: () => void;
  onClickInputFile: () => void;
  onUploadInputFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clearUploadData: () => void;
  uploadImage: () => void;
  clickDefaultButton: () => void;
}

export const UploadModalUI = React.memo(function UploadModalUI({
  visible,
  type,
  imageName,
  imageSize,
  imageType,
  imageUrl,
  uploaded,
  isDefault,
  onClose,
  domLabel,
  onClickInputFile,
  onUploadInputFile,
  clearUploadData,
  uploadImage,
  clickDefaultButton,
}: UploadModalVAProps) {
  return (
    <Modal open={visible} onClose={onClose}>
      <Wrapper>
        <LogoWrap uploaded={`${uploaded}`}>
          <Top>
            <Box>
              <Notice>
                <Box component={'span'}>이미지 파일을 선택해주세요.</Box>
                <Box component={'span'}>(jpeg, png 확장자만 지원합니다.)</Box>
              </Notice>
              <FileWrap>
                <FileIcon uploaded={`${uploaded}`} />
                <Box ref={domLabel} component={'label'} htmlFor='upload_image' style={{ display: 'none' }}></Box>
                <Box
                  component={'input'}
                  type='file'
                  accept='image/jpeg, image/png'
                  style={{ display: 'none' }}
                  id='upload_image'
                  onChange={onUploadInputFile}
                />
                <TextField
                  uploaded={`${uploaded}`}
                  id='upload_logo'
                  label='File'
                  focused
                  color={uploaded ? 'blue' : 'gray_2'}
                  type='button'
                  onClick={onClickInputFile}
                  value={imageName ? '' : 'Choose File'}
                />
                {uploaded ? (
                  <>
                    <DeleteIconButton disableRipple onClick={clearUploadData}>
                      <CgClose />
                    </DeleteIconButton>
                    <FileButton disableRipple variant='contained' color='blue' onClick={onClickInputFile}>
                      <Typography>
                        <Typography component={'span'} sx={{ whiteSpace: 'nowrap' }}>
                          {imageName}
                        </Typography>
                        {imageSize}KB
                      </Typography>
                    </FileButton>
                  </>
                ) : null}
              </FileWrap>
            </Box>
          </Top>
          {uploaded ? (
            <PreviewWrap>
              <img src={`data:${imageType};base64,${imageUrl}`} alt='previewImage' />
            </PreviewWrap>
          ) : null}
          <ButtonWrapper>
            {uploaded ? (
              <>
                <ButtonAfterUpload color='green' sx={{ borderRight: '1px solid #edeeef' }} onClick={uploadImage}>
                  적용
                </ButtonAfterUpload>
                <ButtonAfterUpload color='gray_1' onClick={onClose}>
                  취소
                </ButtonAfterUpload>
              </>
            ) : (
              <DefaultLogoButton
                color='gray_1'
                disabled={type === 'logo' ? isDefault : false}
                onClick={clickDefaultButton}
              >
                기본 {type === 'logo' ? '로고' : '프로필'} 변경
              </DefaultLogoButton>
            )}
          </ButtonWrapper>
        </LogoWrap>
      </Wrapper>
    </Modal>
  );
});
