import { OutlinedInput, FormHelperText as MuiFormHelperText, styled, Box } from '@mui/material';

export const Input = styled(OutlinedInput)(props => ({
  fontSize: '1rem',
  width: '100%',
  height: '2.5rem',
  marginBottom: '0.5rem',
  outline: 'none',
  '&.Mui-disabled.MuiInputBase-colorGray_1': {
    backgroundColor: '#f5f5f5',
    '& input': {
      WebkitTextFillColor: 'var(--gray_1)',
    },
    '& fieldset': {
      borderColor: 'var(--gray-2) !important',
    },
  },
  '&.Mui-error ': {
    '& fieldset': {
      borderColor: `${props.theme.palette.red.main} !important`,
    },
  },
  '&.Mui-focused': {
    fieldset: {
      borderWidth: '1px !important',
    },
  },
  '& input': {
    cursor: `${props.type === 'select' ? 'pointer' : 'auto'}`,
    padding: '0.5rem 1rem 0.435rem',

    '&::placeholder': {
      color: '#c7c7c7 !important',
      transition: 'none',
      fontWeight: '300',
      opacity: '1',
      fontSize: '1rem',
    },
    '&:disabled': {
      backgroundColor: '#f5f5f5 !important',
      cursor: 'default',
    },
  },

  '&.dot_password': {
    input: {
      WebkitTextSecurity: 'disc',
    },
  },

  '&:hover': {
    'input, fieldset': {
      borderColor: 'var(--gray-2)',
    },
  },
  '& fieldset': {
    borderColor: 'var(--gray-2) !important',
    legend: {
      outline: 'none',
    },
  },

  '.password_icon': {
    position: 'absolute',
    right: '0.9rem',
    width: '1.4rem',
    height: '1.4rem',
    cursor: 'pointer',
    color: '#bdbbbc',
  },
}));

export const ProductPeriod = styled(Box)({
  left: '1rem',
  top: '2.84rem',
  position: 'absolute',
  fontSize: '1rem',
  span: {
    // color: '#F48587',
    color: 'red',
    marginLeft: '0.25rem',
  },
  '@media (max-width: 959px)': {
    top: '2.74rem',
  },
});

export const ErrorText = styled(MuiFormHelperText)(() => {
  const top_bottom_position = { top: 'calc(2.25rem / 3)' };
  return {
    position: 'absolute',
    right: '0',
    color: 'var(--red)',
    fontSize: '0.85rem',
    ...top_bottom_position,
  };
});
