import React, { useState } from 'react';
import { InputTitleUI, InputTitleVAProps } from './InputTitle.Presenter';

export const InputTitleContainer = React.memo(function InputTitleContainer(
  props: Omit<InputTitleVAProps, 'open' | 'setOpen'>,
) {
  const [open, setOpen] = useState(false);

  const props_obj = {
    ...props,
    open,
    setOpen,
  };

  return <InputTitleUI {...props_obj} />;
});
