import { Input, ErrorText, ProductPeriod } from './InputText.Styles';
import { InputTitleContainer } from 'components/common/InputTitle/InputTitle.Container';
import { Box, InputBaseComponentProps, SxProps, Theme } from '@mui/material';
import React, { ReactElement } from 'react';

export interface InputTextVAProps {
  value: string;
  onChangeValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: any) => void;
  onBlur?: (e: any) => void;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  errorText?: string;
  errorTextStyle?: SxProps<Theme>;
  autoFocus?: boolean;
  title?: string;
  required?: boolean;
  className?: string;
  readOnly?: boolean;
  period?: string;
  product?: string;
  tooltip?: {
    text: ReactElement;
    direction: TooltipDirection;
  };
  inputProps?: InputBaseComponentProps;
}

export const InputTextUI = React.memo(function InputTextUI({
  title,
  required,
  value,
  onChangeValue,
  onFocus,
  placeholder,
  disabled,
  error,
  errorText,
  autoFocus,
  className,
  readOnly,
  errorTextStyle,
  period,
  product,
  tooltip,
  inputProps,
  onBlur,
}: InputTextVAProps) {
  return (
    <>
      {title ? <InputTitleContainer title={title} required={required} tooltip={tooltip} /> : null}
      <Box sx={{ width: 1 }}>
        <Input
          placeholder={placeholder}
          color='gray_1'
          disabled={disabled}
          value={value}
          onChange={onChangeValue}
          error={error}
          autoFocus={autoFocus}
          autoComplete={'off'}
          className={className}
          readOnly={readOnly}
          onFocus={onFocus}
          onBlur={onBlur}
          inputProps={inputProps}
        />
        {product ? (
          <ProductPeriod>
            {product} {period ? <Box component={'span'}>{period}</Box> : null}
          </ProductPeriod>
        ) : null}
        {error && errorText ? <ErrorText sx={errorTextStyle}>{errorText}</ErrorText> : null}
      </Box>
    </>
  );
});
