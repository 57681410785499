export const confirm_contents: { [key: string]: { title: string; sub_title?: string } } = {
  logout: { title: '오래영어 관리자를 종료하시겠습니까?' },
  set_direct_input: { title: 'target 수동으로 입력하시겠습니까?' },
  update_direct_input: { title: '입력하신 target 수정하시겠습니까?' },
  add_contents: { title: 'target 등록하시겠습니까?' },
  update_contents: { title: 'target 수정하시겠습니까?' },
  copy_contents: { title: 'target 복사하시겠습니까?' },
  delete_contents: {
    title: '선택 항목을 정말 삭제하시겠습니까?',
    sub_title: '<span class="text-gray-1">(삭제된 target 복구되지 않습니다.)</span>',
  },
  delete_contents_2: { title: 'target 삭제하시겠습니까?' },
  move_contents: { title: 'target 이동하시겠습니까?' },
  print_contents: {
    title: 'target 출력하시겠습니까?',
  },
  save_contents: {
    title: 'target 저장하시겠습니까?',
  },
  delete_books: {
    title: '교재를 정말 삭제하시겠습니까?',
    sub_title: '<span class="text-gray-1">(삭제 시, 배포된 스케쥴까지 모두 삭제됩니다.)</span>',
  },
  delete_folder: {
    title: '폴더를 정말 삭제하시겠습니까?',
    sub_title: '<span class="text-gray-1">(폴더 내 교재들도 삭제되며 삭제된 폴더는 복구되지 않습니다.)</span>',
  },
  delete_contents_normal: { title: 'target 삭제하시겠습니까?' },
  registering: { title: '작성 중인 내용이 있습니다.\r\ntarget' },
  update_student_status: { title: '선택 학생을 target 하시겠습니까?' },
  reset_image: { title: '기본 target 설정하시겠습니까?' },
  registration_receipt: {
    title: '지출증빙 정보를 등록하시겠습니까?',
    sub_title: '<span class="text-gray-1">(등록된 정보는 변경 불가합니다.)</span>',
  },
  change_study_setting: {
    title: '<div>현재 학습 설정을 권장합니다.</div><div>그래도 수정하시겠습니까?</div>',
  },
  set_schedule: {
    title: '교재를 배포하시겠습니까?',
  },
  check_schedule_books: {
    title: '<div>target 학생의 배포된</div><div>스케쥴 교재를 확인하시겠습니까?</div>',
  },
  delete_schedules: {
    title: '선택한 스케쥴 교재를 정말 삭제하시겠습니까?',
    sub_title: '<span class="text-red">(삭제된 target은 복구되지 않습니다.)</span>',
  },
  delete_unit: {
    title: '해당 유닛을 삭제하시겠습니까?',
  },
  download_leaderboard: {
    title: '리더보드 target을 다운로드 하시겠습니까?',
  },
  create_counsel: {
    title: '상담일지를 등록하시겠습니까?',
  },
  update_counsel: {
    title: '상담일지를 수정하시겠습니까?',
  },
  deduplication: {
    title: '중복 단어를 제거 하시겠습니까?',
  },
  select_multi_books: {
    title: '교재를 다중 선택하시겠습니까?',
    sub_title: '<span class="text-red">(현재의 학습 설정대로 단원 전체가 배포됩니다.)</span>',
  },
  get_unit_report: {
    title: '해당 유닛의 학습 결과를 확인하시겠습니까?',
  },
  delete_temp_book: {
    title: '임시저장 글을 정말 삭제하시겠습니까?',
    sub_title: '<span class="text-red">(삭제된 글은 복구되지 않습니다.)</span>',
  },
  print_delete_duplicate: {
    title: '중복 단어를 제거하시겠습니까?',
  },
  not_deduplicate: {
    title: '삭제된 중복 단어를 복구하시겠습니까?',
  },
  empty: {
    title: 'target',
  },
};

export const confirm_buttons: { [key: string]: string[] } = {
  normal: ['확인', '취소'],
  normal_reverse: ['취소', '확인'],
  edit: ['수정', '취소'],
  logout: ['종료', '취소'],
};
