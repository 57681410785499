import { Modal, Wrapper, Wrap, Title, ButtonWrap } from './ModalCheck.Styles';
import { ButtonContainer } from 'components/common/Button/Button.Container';
import React from 'react';

export interface ModalCheckVAProps {
  visible: boolean;
  title: string;
  onCloseModal: () => void;
}

export const ModalCheckUI = React.memo(function ModalCheckUI({ visible, title, onCloseModal }: ModalCheckVAProps) {
  return (
    <Modal open={visible} onClose={onCloseModal}>
      <Wrapper>
        <Wrap>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
          <ButtonWrap>
            <ButtonContainer color='longvoca' onClick={onCloseModal}>
              확인
            </ButtonContainer>
          </ButtonWrap>
        </Wrap>
      </Wrapper>
    </Modal>
  );
});
