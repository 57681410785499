import { ModalRadioUI } from './ModalRadio.Presenter';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { modalRadioData, ModalRadioDataType, ModalRadioContentsType } from 'recoil/modalRadio';
import { modalConfirmData, openModalConfirm } from 'recoil/modalConfirm';
import { UserInfoType, userInfoState } from 'recoil/userInfo';
import { useMemo, useCallback } from 'react';
import React from 'react';
import { useMutation } from 'react-query';
import { fetchDeleteApi } from 'utils/api';
import { modalAlertData, ModalAlertDataType, openModalAlert } from 'recoil/modalAlert';
import { autoHypenPhone } from 'utils/tools';
import validation from 'utils/validation';
import { DIRECT_INPUT_ID_REGEX } from 'utils/constants';

export type { ModalRadioContentsType } from 'recoil/modalRadio';

export const ModalRadioContainer = React.memo(function ModalRadioContainer() {
  const userInfo = useRecoilValue<UserInfoType>(userInfoState);
  const setModalAlert = useSetRecoilState<ModalAlertDataType>(modalAlertData);
  const setModalConfirm = useSetRecoilState(modalConfirmData);
  const [modalRadioState, setModalRadioState] = useRecoilState<ModalRadioDataType>(modalRadioData);

  const checkValue = useMemo(() => {
    let result = false;
    if (modalRadioState.oldValue.id !== modalRadioState.curValue.id) {
      if (modalRadioState.curValue.dropdownContents) {
        if (modalRadioState.curValue.selectedDropdownContent) {
          result = true;
        }
      } else if (modalRadioState.curValue.visibleInput) {
        const validation_type = String(modalRadioState.curValue.id).replace(DIRECT_INPUT_ID_REGEX, '');
        const isError = !validation[validation_type](modalRadioState.curValue.inputValue);
        if (!isError && modalRadioState.curValue.inputValue) {
          result = true;
        }
      } else {
        result = true;
      }
    } else {
      if (modalRadioState.curValue.dropdownContents || modalRadioState.curValue.selectedDropdownContent) {
        result = false;
        if (modalRadioState.curValue.selectedDropdownContent) {
          result = true;
          if (modalRadioState.oldValue.selectedDropdownContent) {
            if (
              modalRadioState.oldValue.selectedDropdownContent.id ===
              modalRadioState.curValue.selectedDropdownContent.id
            ) {
              result = false;
            } else {
              result = true;
            }
          }
        }
      }
    }
    return result;
  }, [modalRadioState]);

  const onClickButton = useCallback(() => {
    if (checkValue) {
      // 확인
      modalRadioState.updateValue(modalRadioState);
    } else {
      // 취소
      modalRadioState.clickCancel();
    }

    if (modalRadioState.curValue.name !== '예약 배포') {
      onCancel();
    }
  }, [modalRadioState, checkValue]);

  const onCancel = useCallback(() => {
    modalRadioState.clickCancel();
    setModalRadioState({
      id: '',
      visible: false,
      title: '',
      subTitle: '',
      contents: [],
      oldValue: { id: '', name: '' },
      curValue: { id: '', name: '' },
      updateValue: (modalRadioState: ModalRadioDataType) => {
        return;
      },
      clickCancel: () => {
        return;
      },
    });
  }, [modalRadioState]);

  const onChangeRadio = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const id = e.target.value;
      const find_item = {
        ...modalRadioState.contents.find(content => String(content.id) === id),
      } as ModalRadioContentsType;

      if (find_item) {
        if (find_item && find_item.dropdownContents && modalRadioState.oldValue.selectedDropdownContent) {
          const include_selected_content_item = {
            ...find_item,
            selectedDropdownContent: { ...modalRadioState.oldValue.selectedDropdownContent },
          };
          setModalRadioState({
            ...modalRadioState,
            curValue: include_selected_content_item,
          });
          return;
        }

        if (typeof find_item.id == 'string' && find_item.id.includes('direct_')) {
          // 직접입력 처음 클릭 시,
          const validation_type = find_item.id.replace(DIRECT_INPUT_ID_REGEX, '');

          if (validation_type == 'phone') {
            find_item.visibleInput = true;
            find_item.placeholder = '번호를 입력하세요.';
            find_item.inputValue = '';

            const tmp_contents = [...modalRadioState.contents.map(item => ({ ...item }))];
            const direct_content = tmp_contents.find(item => String(item.id).includes('direct_'));
            if (direct_content) {
              direct_content.visibleInput = true;
              direct_content.placeholder = '번호를 입력하세요.';
              direct_content.inputValue = '';
            }

            setModalRadioState({
              ...modalRadioState,
              contents: tmp_contents,
              curValue: find_item,
            });
            return;
          }
        }

        const tmp_contents = [...modalRadioState.contents.map(item => ({ ...item }))];
        const direct_content = tmp_contents.find(item => String(item.id).includes('direct_'));
        if (direct_content) {
          direct_content.visibleInput = false;
          direct_content.inputValue = '';
          direct_content.placeholder = '';
        }

        setModalRadioState({
          ...modalRadioState,
          contents: tmp_contents,
          curValue: find_item,
        });
      }
    },
    [modalRadioState],
  );

  const handleInput = useCallback(
    (value: string, id: string | number) => {
      const cur_id = `${id}`;
      const validation_type = cur_id.replace(DIRECT_INPUT_ID_REGEX, '');

      if (['phone'].includes(validation_type)) {
        if (value.length > 13) return;

        const input_num = Number(value.replace(/-/g, ''));

        if (Number.isNaN(input_num)) {
          // 숫자 이외의 값 입력시
          openModalAlert({ setModalAlert }, 'only_number');
          return;
        }

        const check = value.match(/[^0-9|^-]/gi);
        if (check && check.length > 0) {
          return;
        } else {
          const cur_val = autoHypenPhone(value);
          const tmp_contents = [...modalRadioState.contents.map(item => ({ ...item }))];
          const direct_content = tmp_contents.find(item => String(item.id).includes('direct_'));
          if (direct_content) {
            direct_content.inputValue = cur_val;
            setModalRadioState({
              ...modalRadioState,
              contents: tmp_contents,
              curValue: direct_content,
            });
          }
        }
      }
    },
    [modalRadioState],
  );

  const onClickDropdownItem = useCallback(
    (idx: number) => {
      if (modalRadioState.curValue.dropdownContents) {
        const selected_item = modalRadioState.curValue.dropdownContents[idx];
        const new_contents = modalRadioState.contents.map(content => {
          if (modalRadioState.curValue.id === content.id) {
            return {
              ...content,
              selectedDropdownContent: selected_item,
            };
          } else {
            return { ...content };
          }
        });
        const new_cur_value = { ...modalRadioState.curValue, selectedDropdownContent: selected_item };
        setModalRadioState(prev => ({
          ...prev,
          contents: new_contents,
          curValue: new_cur_value,
        }));
      }
    },
    [modalRadioState],
  );

  const deleteTempBookApi = useMutation(fetchDeleteApi);

  const onClickDeleteTempBook = useCallback(async () => {
    const temp_book_id = modalRadioState.curValue.id;
    const uri = `/customers/${userInfo.center_id}/accounts/${userInfo.id}/books/temp/${temp_book_id}`;

    const onSuccess = async () => {
      try {
        const res = await deleteTempBookApi.mutateAsync({ uri });
        const response: ResType = await res.json();

        if (response.result) {
          setModalRadioState({
            id: '',
            visible: false,
            title: '',
            subTitle: '',
            contents: [],
            oldValue: { id: '', name: '' },
            curValue: { id: '', name: '' },
            updateValue: (modalRadioState: ModalRadioDataType) => {
              return;
            },
            clickCancel: () => {
              return;
            },
          });
          openModalAlert({ setModalAlert }, 'delete_temporary_book');
        }
      } catch (error) {
        console.log(error);
      }
    };

    openModalConfirm({ setModalConfirm }, 'normal_reverse', 'delete_temp_book', onSuccess);
  }, [modalRadioState]);

  const onClickSetTempBook = useCallback(async () => {
    modalRadioState.updateValue(modalRadioState);
  }, [modalRadioState]);

  const props_obj = {
    ...modalRadioState,
    checkValue,
    onClickButton,
    onChangeRadio,
    onCancel,
    onClickDropdownItem,
    onClickDeleteTempBook,
    onClickSetTempBook,
    handleInput,
  };
  return <ModalRadioUI {...props_obj} />;
});
