import { validatePassword } from './tools';
import { ACCEPT_IMAGE_TYPE } from './constants';

const validation: { [key: string]: (value: any) => boolean } = {
  file: (file: File): boolean => {
    return ACCEPT_IMAGE_TYPE.includes(file.type);
  },
  brn: (value: string): boolean => {
    if (value.length != 0) {
      const brnMap = value
        .replace(/[-]/g, '')
        .split('')
        .map(number => parseInt(number, 10));

      if (brnMap.length == 10) {
        const keyArr = [1, 3, 7, 1, 3, 7, 1, 3, 5];

        let chk = 0;
        keyArr.forEach((key, kIdx) => {
          chk += key * brnMap[kIdx];
        });

        chk += parseInt(String((keyArr[8] * brnMap[8]) / 10), 10);
        return Math.floor(brnMap[9]) === (10 - (chk % 10)) % 10;
      }
      return false;
    } else {
      return true;
    }
  },
  phone: (value: string): boolean => {
    if (value.length != 0) {
      const reg_phone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
      if (reg_phone.test(value)) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  },
  name: (value: string): boolean => {
    const notBeIncludedInTheNameArray = [';'];
    const isInvalidName = notBeIncludedInTheNameArray.some(notBeIncludedInTheNameitem => {
      return value.includes(notBeIncludedInTheNameitem);
    });
    if (isInvalidName) {
      return false;
    } else {
      return true;
    }
  },
  password: (value: string): boolean => {
    if (value.length != 0) {
      const isPassword = validatePassword(value);
      if (!isPassword) {
        return false;
      } else {
        if (value.length > 20) {
          return false;
        }
      }
    }
    return true;
  },
  loginId: (value: string): boolean => {
    if (value.length != 0) {
      const reg_id = /^\w{2,}$/g;
      return reg_id.test(value);
    }
    return true;
  },
  email: (value: string): boolean => {
    if (value.length != 0) {
      const reg_email = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
      if (reg_email.test(value)) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  },
  emoji: (value: object | string[] | string): boolean => {
    const emoji_regex =
      /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*/g;

    let res = true;
    if (typeof value == 'string') {
      if (emoji_regex.test(value)) {
        return false;
      }
    } else {
      for (const [key, val] of Object.entries(value)) {
        if (typeof val == 'string') {
          if (emoji_regex.test(val)) {
            return false;
          }
        } else if (val == null || (typeof val !== 'object' && typeof val !== 'string')) {
          if (emoji_regex.test(`${val}`)) {
            return false;
          }
        } else {
          const vali = validation.emoji(val);
          if (vali == false) {
            res = false;
            break;
          }
        }
      }
    }
    return res;
  },
};

export default validation;
