import { styled, Box, Modal as MuiModal } from '@mui/material';

export const Modal = styled(MuiModal)({
  backgroundColor: 'rgba(33, 33, 33, 0.46)',
  transition: '0.2s cubic-bezier(0.25, 0.8, 0.5, 1), z-index 1ms !important',
  '& > .MuiBox-root': {
    '&:focus-visible': {
      outline: 'none',
    },
  },
});

export const Wrapper = styled(Box)({
  borderRadius: '4px',
  overflow: 'hidden',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
  boxShadow: 'none',
});

export const Wrap = styled(Box)({
  backgroundColor: '#fff',
  borderRadius: '4px',
  overflow: 'hidden',
});

export const ModalAlert = styled(Box)(props => ({
  position: 'relative',
  width: '35.7rem',
  maxWidth: '480px',
  borderRadius: '4px',
  padding: '32px 16px',
  backgroundColor: '#000',
  opacity: '0.75',
  [props.theme.breakpoints.up('xxl')]: {
    fontSize: '1.1rem',
  },
  [props.theme.breakpoints.down('xxl')]: {
    fontSize: '1.2rem',
  },
  whiteSpace: 'pre-wrap',
}));

export const Title = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  textAlign: 'center',
  [props.theme.breakpoints.up('xxl')]: {
    fontSize: '1.1rem',
  },
  [props.theme.breakpoints.down('xxl')]: {
    fontSize: '1.2rem',
  },
  lineHeight: '2rem',
  color: '#fff !important',
  caretColor: '#fff !important',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',

  '.sub_title': {
    fontWeight: '300',
    fontSize: '1.1rem',
  },
}));
