import { styled, Box, CircularProgress } from '@mui/material';

export const CircularProgressBottom = styled(CircularProgress)(props => ({
  color: props.theme.palette.grey[props.theme.palette.mode === 'light' ? 300 : 800],
}));

export const LoadingBox = styled(Box)({
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  display: 'flex',
  top: '0',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(33, 33, 33, .46)',
  zIndex: '999999999',
});

export const CircularProgressTop = styled(CircularProgress)({
  color: '#4620e9',
  animationDuration: '650ms',
  position: 'absolute',
});

export const LoadingLabel = styled(Box)({
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: '#FFF',
  fontSize: '1.35rem',
  fontWeight: 'bold',
  position: 'absolute',
});
