import { LoadingWithRecoilUI } from './LoadingWithRecoil.Presenter';
import { BoxProps } from '@mui/material';
import { loadingState } from 'recoil/loading';
import { useRecoilValue } from 'recoil';

export function LoadingWithRecoilContainer({ sx }: BoxProps) {
  const loading = useRecoilValue(loadingState);
  const { visible } = loading;

  return <LoadingWithRecoilUI visible={visible} sx={sx} />;
}
