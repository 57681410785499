import { styled, Box } from '@mui/material';
export { Modal, Wrapper } from 'components/common/ModalAlert/ModalAlert.Styles';

export const Wrap = styled(Box)(props => ({
  height: 'auto',
  borderRadius: '6px',
  overflow: 'hidden',
}));

export const Header = styled(Box)(props => ({
  width: '100%',
  height: '3.5rem',
  padding: '0 1rem',
  fontSize: '1.1rem',
  background: 'linear-gradient(#652ae9, #4620e9)',
  color: props.theme.palette.white.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '.sub-title': {
    fontSize: '0.875rem',
    marginLeft: '0.25rem',
  },
}));

export const HeaderIconWrap = styled(Box)(props => ({
  width: '2rem',
  height: '2rem',
  borderRadius: '50%',
  cursor: 'pointer',
  backgroundColor: props.theme.palette.white.main,
  color: props.theme.palette.longvoca.main,
  svg: {
    width: '1.7rem',
    height: '1.7rem',
    strokeWidth: 30,
    marginRight: '-1px',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const Body = styled(Box)(props => ({
  width: '22.5rem',
  height: '32.5rem',
  backgroundColor: props.theme.palette.white.main,
}));

export const Top = styled(Box)(props => ({
  width: '100%',
  height: '3rem',
  fontSize: '1.025rem',
  fontWeight: 'bold',
  backgroundColor: props.theme.palette.gray_4.main,
  borderBottom: `1px solid ${props.theme.palette.gray_3.main}`,
  display: 'flex',
  '& > div': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const Middle = styled(Box)(props => ({
  color: props.theme.palette.gray_1.main,
  height: '25.5rem',
  display: 'flex',
  flexDirection: 'column',
  '& > div': {
    width: '100%',
    overflowY: 'auto',
  },
}));

interface RowProps {
  selected?: boolean;
}

export const Row = styled(Box)<RowProps>(props => {
  const selected_style = props.selected
    ? { color: props.theme.palette.cyan_2.main + ' !important', backgroundColor: props.theme.palette.sky_blue.main }
    : {};

  return {
    width: '100%',
    height: '12.5%',
    paddingLeft: '2rem',
    alignItems: 'center',
    borderBottom: `1px solid ${props.theme.palette.gray_3.main}`,
    display: 'flex',
    color: 'rgba(0, 0, 0, 0.87)',
    cursor: 'pointer',
    ...selected_style,

    '& > svg': {
      width: '1rem',
      height: '1rem',
    },

    '&.disabled': {
      cursor: 'initial',
      color: '#e0e0e0',
    },
  };
});

export const Name = styled(Box)({
  width: 'calc(100% - 1.5rem)',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  marginLeft: '0.5rem',
  '& > div': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

export const Deco = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  marginRight: '0.5rem',
  borderLeft: `1px dotted ${theme.palette.gray_1.main}`,
  borderBottom: `1px dotted ${theme.palette.gray_1.main}`,
  transform: 'translateY(-50%)',
  width: '0.65rem',
  height: '0.65rem',
}));

export const Bottom = styled(Box)({
  width: '100%',
  height: '4rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  button: {
    padding: '0',
  },
});
