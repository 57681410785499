import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc, setDoc, onSnapshot, Unsubscribe } from 'firebase/firestore';
import { getAuth, signInAnonymously, onAuthStateChanged } from 'firebase/auth';
declare let window: any;

interface MissionDocType {
  fb_uid: number;
  step?: string;
}
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// firebaseConfig 정보로 firebase 시작
const app = initializeApp(firebaseConfig);

// firebase 익명 로그인 -> uid 뽑기위해
const auth = getAuth();
signInAnonymously(auth).catch(error => {
  const errorCode = error.code;
  const errorMessage = error.message;
});

onAuthStateChanged(auth, user => {
  if (user) {
    window.FB_UID = user.uid;
  } else {
    window.FB_UID = undefined;
  }
});

// firebase의 firestore 인스턴스를 변수에 저장
const collection_name =
  process.env.REACT_APP_NODE_ENV != 'development'
    ? (process.env.REACT_APP_REAL_FIRESTORE_DB_NAME as string)
    : (process.env.REACT_APP_DEV_FIRESTORE_DB_NAME as string);
const db = getFirestore(app);

// users collection 의 user doc 조회 함수
export async function getStepInFireStore(centerId: number) {
  const mission_ref = doc(db, collection_name, String(centerId));
  const mission_snapshot = await getDoc(mission_ref);

  if (mission_snapshot.exists()) {
    return mission_snapshot.data();
  } else {
    //  최초 로그인
    return null;
  }
}

// firestore랑 sync 맞추는 함수 짜야함
export function bindMissionDocs(centerId: number, get_step: () => void) {
  const mission_ref = doc(db, collection_name, String(centerId));
  return onSnapshot(mission_ref, { includeMetadataChanges: true }, doc => {
    const data = doc.data() as MissionDocType;
    if (Number(data.step) == 4) {
      get_step();
    }
  });
}

export async function setMissionDocs(centerId: number, data: MissionDocType, get_step: () => void) {
  const mission_ref = doc(db, collection_name, String(centerId));
  await setDoc(mission_ref, data, { merge: true }).then(res => {
    window.FB_UNSCRIBE = bindMissionDocs(centerId, get_step);
  });
}

// 로그인 시, 사용할 firebase 모듈
export async function checkChangeMissionStep(cId: number, get_step: () => void) {
  const missionDoc = await getStepInFireStore(cId);
  const data: MissionDocType = {
    fb_uid: window.FB_UID,
  };
  if (missionDoc) {
    const mission_document = missionDoc as MissionDocType;
    // 로그인 검증 로직
    if (mission_document.fb_uid && mission_document.step !== null) {
      //  중복 로그인 검증 로직 필요
      if (mission_document.fb_uid == window.FB_UID) {
        // 같은 기기에서 접속
        // delete data.fb_cid;
        // await setUserInFireStore(accountId, data, success_cb);
        return 'END';
      }
    } else {
      // 중복 로그인 상태 아님
      // await setUserInFireStore(accountId, data, success_cb);
      return 'END';
    }
  } else {
    // 최초 로그인
    // await setUserInFireStore(accountId, data, success_cb);
    return 'END';
  }
}
