import { atom, SetterOrUpdater } from 'recoil';
import { confirm_contents, confirm_buttons } from 'utils/modal_confirm_contents';
import { setModalTitle } from 'utils/tools';

export interface ModalConfirmDataType {
  visible: boolean;
  title: string;
  left: string;
  right: string;
  onSuccess: () => void;
  onCancel?: () => void;
}

export const modalConfirmData = atom<ModalConfirmDataType>({
  key: 'modalConfirm',
  default: {
    visible: false,
    title: '',
    left: '',
    right: '',
    onSuccess: () => {
      return;
    },
    onCancel: () => {
      return;
    },
  },
});

interface ModalConfirmSetter {
  setModalConfirm: SetterOrUpdater<ModalConfirmDataType>;
}

export const openModalConfirm = (
  { setModalConfirm }: ModalConfirmSetter,
  button_type: string,
  type: string,
  onSuccess: () => void,
  onCancel?: () => void,
  target?: string,
) => {
  const title = confirm_contents[type] ? confirm_contents[type].title : 'type이 잘 못되었습니다.';
  const subTitle =
    confirm_contents[type] && confirm_contents[type].sub_title ? confirm_contents[type].sub_title : undefined;
  const payload = {
    title,
    subTitle,
    target,
  };
  const modal_title = setModalTitle(payload);
  const left_button_label = confirm_buttons[button_type][0];
  const right_button_label = confirm_buttons[button_type][1];

  setModalConfirm({
    visible: true,
    title: modal_title,
    left: left_button_label,
    right: right_button_label,
    onSuccess,
    onCancel,
  });
};

export const closeModalConfirm = ({ setModalConfirm }: ModalConfirmSetter) => {
  setModalConfirm({
    visible: false,
    title: '',
    left: '',
    right: '',
    onSuccess: () => {
      return;
    },
    onCancel: () => {
      return;
    },
  });
};
