import { atom, SetterOrUpdater } from 'recoil';
import { alert_contents } from 'utils/modal_alert_contents';
import { setModalTitle } from 'utils/tools';

export interface ModalAlertDataType {
  visible: boolean;
  title: string;
}

export const modalAlertData = atom<ModalAlertDataType>({
  key: 'modalAlert',
  default: {
    visible: false,
    title: '',
  },
});

interface ModalAlertSetter {
  setModalAlert: SetterOrUpdater<ModalAlertDataType>;
}

export const openModalAlert = (
  { setModalAlert }: ModalAlertSetter,
  type: string,
  target?: string,
  callback?: () => void,
) => {
  const title = alert_contents[type] ? alert_contents[type].title : 'type이 잘 못되었습니다.';
  const payload = {
    title,
    target,
  };
  const modal_title = setModalTitle(payload);
  setModalAlert({
    visible: true,
    title: modal_title,
  });
  setTimeout(() => {
    setModalAlert({
      visible: false,
      title: '',
    });
    if (callback) {
      setTimeout(() => {
        callback();
      }, 0);
    }
  }, 2000);
};
