import { TableQueryType, TableFieldsType } from 'components/common/Table/Table.Presenter';
import { atom, SetterOrUpdater } from 'recoil';
import {
  teacher_list_fields,
  class_list_fields,
  student_list_fields,
  list_excel_fields,
  book_make_table_fields,
  book_list_fields,
  test_list_fields,
  main_fields,
  daily_report_fields,
  period_report_fields,
  monthly_report_fields,
  accumulated_report_fields,
  award_history,
  message_detail,
  notice_fields,
  update_news_fields,
  check_usage_fields,
  payment_fee_fields,
  studying_book_fields,
  studied_book_fields,
  study_journal_fields,
  learning_status_fields,
  realtime_inquiry_fields,
  free_main_fields,
} from 'utils/table_fields';
import { addUsersInClass, addClassesInTeacher } from 'utils/tools';
import { UserInfoType } from './userInfo';

export interface TableDataType {
  tableItems: TableItemsType[];
  checkedCount: number;
  checkedIdx: number[];
  tableQuery: TableQueryType;
  tableFields: TableFieldsType[];
  searchFields?: string[];
}

export interface TableRecoilType {
  [key: string]: TableDataType;
}

export const initialTableState: TableRecoilType = {
  teacher: {
    tableItems: [],
    checkedCount: 0,
    checkedIdx: [],
    tableQuery: { target: '', sort: '', page: 1, max: 10 },
    tableFields: teacher_list_fields,
    searchFields: ['name', 'accountId'],
  },
  class: {
    tableItems: [],
    checkedCount: 0,
    checkedIdx: [],
    tableQuery: { target: '', sort: '', page: 1, max: 10 },
    tableFields: class_list_fields,
    searchFields: ['name'],
  },
  student: {
    tableItems: [],
    checkedCount: 0,
    checkedIdx: [],
    tableQuery: { target: '', sort: '', page: 1, max: 10 },
    tableFields: student_list_fields,
    searchFields: ['name', 'accountId'],
  },
  listExcel: {
    tableItems: [],
    checkedCount: 0,
    checkedIdx: [],
    tableQuery: { target: '', sort: '', page: 1, max: 10 },
    tableFields: list_excel_fields,
  },
  bookMaking: {
    tableItems: [],
    checkedCount: 0,
    checkedIdx: [],
    tableQuery: { target: '', sort: '', page: 1, max: 10 },
    tableFields: book_make_table_fields,
  },
  bookList: {
    tableItems: [],
    checkedCount: 0,
    checkedIdx: [],
    tableQuery: { target: '', sort: '', page: 1, max: 10 },
    tableFields: book_list_fields,
    searchFields: ['name'],
  },
  main: {
    tableItems: [],
    checkedCount: 0,
    checkedIdx: [],
    tableQuery: { target: '', sort: '', page: 1, max: 10 },
    tableFields: main_fields,
  },
  freeMain: {
    tableItems: [],
    checkedCount: 0,
    checkedIdx: [],
    tableQuery: { target: '', sort: '', page: 1, max: 10 },
    tableFields: free_main_fields,
  },
  testList: {
    tableItems: [],
    checkedCount: 0,
    checkedIdx: [],
    tableQuery: { target: '', sort: '', page: 1, max: 10 },
    tableFields: test_list_fields,
  },
  dailyReport: {
    tableItems: [],
    checkedCount: 0,
    checkedIdx: [],
    tableQuery: { target: '', sort: '', page: 1, max: 10 },
    tableFields: daily_report_fields,
    searchFields: ['name'],
  },
  periodReport: {
    tableItems: [],
    checkedCount: 0,
    checkedIdx: [],
    tableQuery: { target: '', sort: '', page: 1, max: 10 },
    tableFields: period_report_fields,
    searchFields: ['name'],
  },
  monthlyReport: {
    tableItems: [],
    checkedCount: 0,
    checkedIdx: [],
    tableQuery: { target: '', sort: '', page: 1, max: 10 },
    tableFields: monthly_report_fields,
    searchFields: ['name'],
  },
  accumulatedReport: {
    tableItems: [],
    checkedCount: 0,
    checkedIdx: [],
    tableQuery: { target: '', sort: '', page: 1, max: 10 },
    tableFields: accumulated_report_fields,
    searchFields: ['name'],
  },
  studyingBook: {
    tableItems: [],
    checkedCount: 0,
    checkedIdx: [],
    tableQuery: { target: '', sort: '', page: 1, max: 10 },
    tableFields: studying_book_fields,
  },
  studiedBook: {
    tableItems: [],
    checkedCount: 0,
    checkedIdx: [],
    tableQuery: { target: '', sort: '', page: 1, max: 10 },
    tableFields: studied_book_fields,
  },
  studyJournal: {
    tableItems: [],
    checkedCount: 0,
    checkedIdx: [],
    tableQuery: { target: '', sort: '', page: 1, max: 10 },
    tableFields: study_journal_fields,
  },
  awardHistory: {
    tableItems: [],
    checkedCount: 0,
    checkedIdx: [],
    tableQuery: { target: '', sort: '', page: 1, max: 10 },
    tableFields: award_history,
    searchFields: ['name'],
  },
  messageDetail: {
    tableItems: [],
    checkedCount: 0,
    checkedIdx: [],
    tableQuery: { target: '', sort: '', page: 1, max: 10 },
    tableFields: message_detail,
    searchFields: ['student_name'],
  },
  notice: {
    tableItems: [],
    checkedCount: 0,
    checkedIdx: [],
    tableQuery: { target: '', sort: '', page: 1, max: 10 },
    tableFields: notice_fields,
    searchFields: ['title'],
  },
  updateNews: {
    tableItems: [],
    checkedCount: 0,
    checkedIdx: [],
    tableQuery: { target: '', sort: '', page: 1, max: 10 },
    tableFields: update_news_fields,
    searchFields: ['title'],
  },
  checkUsage: {
    tableItems: [],
    checkedCount: 0,
    checkedIdx: [],
    tableQuery: { target: '', sort: '', page: 1, max: 10 },
    tableFields: check_usage_fields,
    searchFields: ['uname', 'accountId'],
  },
  paymentFee: {
    tableItems: [],
    checkedCount: 0,
    checkedIdx: [],
    tableQuery: { target: '', sort: '', page: 1, max: 12 },
    tableFields: payment_fee_fields,
  },
  learningStatus: {
    tableItems: [],
    checkedCount: 0,
    checkedIdx: [],
    tableQuery: { target: '', sort: '', page: 1, max: 10 },
    tableFields: learning_status_fields,
  },
  realtimeInquiry: {
    tableItems: [],
    checkedCount: 0,
    checkedIdx: [],
    tableQuery: { target: '', sort: '', page: 1, max: 10 },
    tableFields: realtime_inquiry_fields,
    searchFields: ['name'],
  },
};

export const tableState = atom<TableRecoilType>({
  key: 'table',
  default: {
    ...initialTableState,
  },
});

interface TableSetter {
  setTableStateData: SetterOrUpdater<TableRecoilType>;
  tableStateData: TableRecoilType;
}

export const spreadTable = async (
  { setTableStateData, tableStateData }: TableSetter,
  tableItems: TableItemsType[],
  tableKey: string,
  userInfo?: UserInfoType,
  callback_fn?: () => void,
) => {
  // 검색결과가 하나일 경우, 펼쳐지도록 하는 함수
  if (tableKey === 'teacher' && userInfo && userInfo.center_id) {
    const teacher_item = tableItems[0];
    await addClassesInTeacher(userInfo.center_id, teacher_item);
  } else if (tableKey === 'class' && userInfo && userInfo.center_id) {
    const class_item = tableItems[0];
    await addUsersInClass(userInfo.center_id, class_item);
  } else if ((tableKey === 'dailyReport' || tableKey == 'realtimeInquiry') && callback_fn) {
    callback_fn();
  }

  for (const x of tableItems) {
    if (x.isCategory) {
      x.show_details = false;
    } else if (tableKey == 'bookList' && x.is_mock) {
      x.show_details = false;
    } else {
      x.show_details = true;
    }
  }

  setTableStateData({
    ...tableStateData,
    [tableKey]: {
      ...tableStateData[tableKey],
      tableItems,
    },
  });
};

export const resetTable = async ({ setTableStateData }: { setTableStateData: SetterOrUpdater<TableRecoilType> }) => {
  const init_table = JSON.parse(JSON.stringify(initialTableState));

  setTableStateData(init_table);
};
