import { atom, SetterOrUpdater } from 'recoil';

export interface DropdownNormalDataType {
  normal: { title: string };
  circle: { title: string };
}

export const dropdownNormalData = atom<DropdownNormalDataType>({
  key: 'dropdownNormal',
  default: {
    normal: {
      title: '',
    },
    circle: {
      title: '',
    },
  },
});

interface DropdownNormalSetter {
  setDropdownNormal: SetterOrUpdater<DropdownNormalDataType>;
}

export const initDropownTitle = ({ setDropdownNormal }: DropdownNormalSetter) => {
  setDropdownNormal(() => ({
    normal: {
      title: '',
    },
    circle: {
      title: '',
    },
  }));
};
