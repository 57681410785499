import { UploadModalUI } from './UploadModal.Presenter';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { uploadModalData, UploadModalDataType, resetUploadModal, clearUploadModal } from 'recoil/uploadModal';
import { modalAlertData, openModalAlert } from 'recoil/modalAlert';
import { modalConfirmData, openModalConfirm } from 'recoil/modalConfirm';
import { userInfoState, UserInfoType } from 'recoil/userInfo';
import { useQuery, useMutation } from 'react-query';
import { fetchFileApi, fetchGetApi, fetchDeleteApi } from 'utils/api';
import { useMemo, useCallback, useRef } from 'react';
import validation from 'utils/validation';
import { IMAGE_DATA_BASE_64 } from 'utils/constants';
import React from 'react';
import {
  ModalDefaultProfileSettingType,
  modalDefaultProfileSettingState,
  openModalDefaultProfileSetting,
} from 'recoil/modalDefaultProfileSetting';

export const UploadModalContainer = React.memo(function UploadModalContainer() {
  const setModalAlert = useSetRecoilState(modalAlertData);
  const setModalConfirm = useSetRecoilState(modalConfirmData);
  const setModalDefaultProfileSetting = useSetRecoilState<ModalDefaultProfileSettingType>(
    modalDefaultProfileSettingState,
  );
  const [userInfo, setUserInfo] = useRecoilState<UserInfoType>(userInfoState);
  const [uploadModal, setUploadModal] = useRecoilState<UploadModalDataType>(uploadModalData);
  const domLabel = useRef<HTMLLabelElement>(null);

  // image file get api
  const getImageUri = useMemo(() => {
    const type = uploadModal.type;
    if (type === 'profile') {
      return `/customers/${userInfo.center_id}/accounts/${userInfo.id}/${type}`;
    } else {
      // logo
      return `/customers/${userInfo.center_id}/${type}`;
    }
  }, [uploadModal.type]);

  const getImageFileApi = useQuery(['getImage', getImageUri], fetchGetApi(getImageUri), {
    retry: 0,
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess: res => {
      const type = uploadModal.type;
      const target = type === 'logo' ? '로고가' : '프로필 사진이';
      setUserInfo(prev => ({
        ...prev,
        logo_type: type === 'logo' ? 'custom' : prev.logo_type,
        profile_type: type === 'profile' ? 'custom' : prev.profile_type,
        logo: type === 'logo' ? IMAGE_DATA_BASE_64 + res.data.logo : prev.logo,
        profile: type === 'profile' ? IMAGE_DATA_BASE_64 + res.data.profile : prev.profile,
      }));
      if (uploadModal.closeCallback) {
        uploadModal.closeCallback();
      }
      resetUploadModal({ setUploadModal });
      setTimeout(() => {
        openModalAlert({ setModalAlert }, 'success_setting', target);
      }, 150);
    },
    onError: e => {
      console.log(e);
    },
  });

  // image file 전송 api
  const uploadImageFileApi = useMutation(fetchFileApi);

  // image reset (서버측에서는 제거) api
  const resetImageFileApi = useMutation(fetchDeleteApi);

  const onClose = useCallback(() => {
    resetUploadModal({ setUploadModal });
  }, []);

  const onClickInputFile = useCallback(() => {
    const label = domLabel.current;
    if (label) {
      label.click();
    }
  }, [domLabel]);

  const onUploadInputFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const uploadInput = document.getElementById('upload_image') as HTMLInputElement;
    if (files && files[0]) {
      const isAccepted = validation.file(files[0]);
      if (!isAccepted) {
        uploadInput.value = '';
        openModalAlert({ setModalAlert }, 'accept_image_type');
        return;
      }

      if (Number(Number(files[0].size / 1024).toFixed(1)) >= 10000) {
        uploadInput.value = '';
        openModalAlert({ setModalAlert }, 'upload_limit');
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          const imageSize = Number(Number(files[0].size / 1024).toFixed(1));
          const imageName = files[0].name;
          const imageUrl = `${`${reader.result}`.replace(`data:${files[0].type};base64,`, '')}`;
          const imageFile = files[0];
          const imageType = files[0].type;
          setUploadModal(prev => ({
            ...prev,
            uploaded: true,
            imageSize,
            imageName,
            imageUrl,
            imageFile,
            imageType,
          }));
        }
      };

      reader.readAsDataURL(files[0]);
    }
  }, []);

  const uploadImage = useCallback(async () => {
    const imageFile = uploadModal.imageFile;
    if (imageFile) {
      if (uploadModal.uploadingCallback) {
        uploadModal.uploadingCallback();
      }

      const type = uploadModal.type;
      const image_form_data = new FormData();
      image_form_data.append(type, imageFile);

      // api 요청
      let uri: string;
      if (type === 'profile') {
        uri = `/customers/${userInfo.center_id}/accounts/${userInfo.id}/${type}`;
      } else {
        // logo
        uri = `/customers/${userInfo.center_id}/${type}`;
      }
      const data = await uploadImageFileApi.mutateAsync({ uri, args: image_form_data });
      if (data.result) {
        getImageFileApi.refetch();
      }
    }
  }, [uploadModal, userInfo]);

  const clearUploadData = useCallback(() => {
    clearUploadModal({ setUploadModal });
  }, []);

  const clickDefaultButton = useCallback(() => {
    const type = uploadModal.type;
    if (type == 'logo') {
      // 로고
      const target = type == 'logo' ? '로고' : '프로필';
      openModalConfirm({ setModalConfirm }, 'normal_reverse', 'reset_image', resetImage, undefined, `${target}로`);
    } else if (type == 'profile') {
      // 프로필
      openModalDefaultProfileSetting({ setModalDefaultProfileSetting, arbitrary: true });
      onClose();
    }
  }, [uploadModal.type]);

  const resetImage = useCallback(async () => {
    let uri: string;
    const type = uploadModal.type;
    if (type === 'profile') {
      uri = `/customers/${userInfo.center_id}/accounts/${userInfo.id}/${type}`;
    } else {
      // logo
      uri = `/customers/${userInfo.center_id}/${type}`;
    }
    const res = await resetImageFileApi.mutateAsync({ uri });
    if (res.status === 200) {
      const type = uploadModal.type;
      const target = type === 'logo' ? '로고가' : '프로필 사진이';
      const result = await res.json();
      setUserInfo(prev => ({
        ...prev,
        logo_type: type === 'logo' ? 'default' : prev.logo_type,
        profile_type: type === 'profile' ? 'default' : prev.profile_type,
        logo: type === 'logo' ? result.data.logo : prev.logo,
        profile: type === 'profile' ? '' : prev.profile,
      }));
      resetUploadModal({ setUploadModal });
      setTimeout(() => {
        openModalAlert({ setModalAlert }, 'success_setting', `기본 ${target}`);
      }, 150);
    }
  }, [userInfo, uploadModal.type]);

  const props_obj = {
    ...uploadModal,
    onClose,
    domLabel,
    onClickInputFile,
    onUploadInputFile,
    clearUploadData,
    uploadImage,
    clickDefaultButton,
  };

  return <UploadModalUI {...props_obj} />;
});
