import { DropdownNormalUI, DropdownNormalVAProps } from './DropdownNormal.Presenter';
import { useState, useMemo, useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { TableRecoilType, tableState } from 'recoil/table';
import { dropdownNormalData, DropdownNormalDataType } from 'recoil/dropdownNormal';
import { openModalAlert, modalAlertData, ModalAlertDataType } from 'recoil/modalAlert';
import React from 'react';

export interface DropdownContainerProps
  extends Pick<
    DropdownNormalVAProps,
    'dropdownContents' | 'type' | 'disabled' | 'title' | 'endIcon' | 'color' | 'className' | 'variant'
  > {
  onClickItem: (idx: number, initDrdopownTitle: () => void) => void;
  checkCount?: number;
  useRecoil?: boolean;
  dropdownKey?: string;
}

export const DropdownNormalContainer = React.memo(function DropdownNormalContainer({
  dropdownContents,
  type,
  disabled,
  title,
  endIcon,
  color,
  className,
  onClickItem,
  checkCount,
  variant,
  useRecoil,
  dropdownKey,
}: DropdownContainerProps) {
  const tableStateData = useRecoilValue<TableRecoilType>(tableState);
  const setModalAlert = useSetRecoilState<ModalAlertDataType>(modalAlertData);
  const [dropdownNormal, setDropdownNormal] = useRecoilState<DropdownNormalDataType>(dropdownNormalData);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    return () => {
      setDropdownNormal(() => ({ normal: { title: '' }, circle: { title: '' } }));
    };
  }, []);

  const open = useMemo(() => {
    return Boolean(anchorEl);
  }, [anchorEl]);

  const onClickDropdownButton = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (dropdownKey) {
        const checked_idx = tableStateData[dropdownKey].checkedIdx;
        const mockItems = tableStateData[dropdownKey].tableItems.filter(
          (item, idx) => checked_idx.includes(idx) && item.is_mock,
        );

        if (mockItems.length > 0) {
          let alert_type = '';
          switch (dropdownKey) {
            case 'class':
              alert_type = 'tmp_class';
              break;
            case 'student':
              alert_type = 'tmp_student';
              break;
            case 'bookList':
              alert_type = 'tmp_books';
              break;
            case 'notice':
              alert_type = 'tmp_notices';
              break;
            case 'dailyReport':
              alert_type = 'tmp_record';
              break;
            case 'periodReport':
              alert_type = 'tmp_record';
              break;
          }

          openModalAlert({ setModalAlert }, alert_type);
          return;
        }
      }
      setAnchorEl(event.currentTarget);
    },
    [dropdownKey, tableStateData],
  );

  const onCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const initDropdownTitle = useCallback(() => {
    if (title) {
      if (type) {
        setDropdownNormal(prev => ({ ...prev, [type]: { title } }));
      } else {
        setDropdownNormal(prev => ({ ...prev, normal: { title } }));
      }
    }
  }, []);

  const onClickMenu = useCallback(
    (idx: number) => {
      onClickItem(idx, initDropdownTitle);
      let title = `${dropdownContents[idx].content}`;
      title = title.replace('_require', '');
      title = title.replace('::disabled', '');
      title = title.replace('::forbidden', '');

      if (useRecoil) {
        if (type) {
          setDropdownNormal(prev => ({ ...prev, [type]: { title } }));
        } else {
          setDropdownNormal(prev => ({ ...prev, normal: { title } }));
        }
      }

      setAnchorEl(null);
    },
    [dropdownContents, title, onClickItem, useRecoil],
  );

  const setMenuDisabled = useCallback(
    (text: string): boolean => {
      for (let i = 0, length = tableStateData['teacher'].checkedIdx.length; i < length; i++) {
        const cur_idx = tableStateData['teacher'].checkedIdx[i];
        if (tableStateData['teacher'].tableItems[cur_idx].permission.label === '대표 관리자') {
          return true;
        }
      }

      if (text.indexOf('::disabled') >= 0) {
        if (checkCount == 1) {
          return false;
        } else {
          return true;
        }
      } else if (text.indexOf('::forbidden') >= 0) {
        return true;
      } else {
        return false;
      }
    },
    [checkCount, tableStateData['teacher']],
  );

  const dropdownTitle = useMemo(() => {
    if (useRecoil) {
      if (type) {
        return dropdownNormal.circle.title;
      } else {
        return dropdownNormal.normal.title;
      }
    } else {
      return title;
    }
  }, [dropdownNormal, useRecoil, title]);

  const props = {
    dropdownContents,
    open,
    anchorEl,
    disabled,
    dropdownTitle,
    title,
    type,
    onClickDropdownButton,
    onClickMenu,
    onCloseMenu,
    setMenuDisabled,
    endIcon,
    color,
    className,
    variant,
  };

  return <DropdownNormalUI {...props} />;
});
