import { ModalCalendarUI } from './ModalCalendar.Presenter';
import { useRecoilState } from 'recoil';
import { ModalCalendarDataType, modalCalendarData, closeModalCalendar } from 'recoil/modalCalendar';
import { useCallback, useMemo, useEffect } from 'react';
import { setLocaleDateString } from 'utils/tools';
import { CalendarPickerView } from '@mui/x-date-pickers';
import React from 'react';

export const ModalCalendarContainer = React.memo(function ModalCalendarContainer() {
  const [modalCalendar, setModalCalendar] = useRecoilState<ModalCalendarDataType>(modalCalendarData);

  useEffect(() => {
    if (modalCalendar.visible && modalCalendar.view === 'year') {
      setTimeout(() => {
        const calendar_root = document.getElementsByClassName('MuiCalendarPicker-root')[0];
        if (calendar_root) {
          const year = curYear;
          const year_elements = document.getElementsByClassName('PrivatePickersYear-root');
          for (let i = 0; i < year_elements.length; i++) {
            const button_wrap_element = year_elements[i].childNodes[0] as any;
            const button_element = year_elements[i].childNodes[0].childNodes[0] as any;
            const button_label = Number(button_element.data);
            if (button_label === year) {
              calendar_root.scrollTo({
                top: button_wrap_element.offsetTop,
                behavior: 'smooth',
              });
              break;
            }
          }
        }
      }, 0);
    }
  }, [modalCalendar.visible]);

  const onCloseModal = useCallback(() => {
    closeModalCalendar({ setModalCalendar });
  }, []);

  const curYear = useMemo(() => {
    // modalCalendar.value 있으면 해당 value 기준 없으면 new Date() 기준
    if (modalCalendar.value) {
      return modalCalendar.value.getFullYear();
    } else {
      return new Date().getFullYear();
    }
  }, [modalCalendar]);

  const onYearChange = useCallback(
    (month: any) => {
      if (modalCalendar.callBackMonthChange) {
        modalCalendar.callBackMonthChange(month, modalCalendar.requestedMonthObJ);
      }
      setModalCalendar(prev => ({
        ...prev,
        view: 'month',
      }));
    },
    [modalCalendar],
  );

  const onMonthChange = useCallback(
    (month: any) => {
      if (modalCalendar.callBackMonthChange) {
        modalCalendar.callBackMonthChange(month, modalCalendar.requestedMonthObJ);
      }
      if (modalCalendar.calendarKey != 'monthlyReport') {
        setModalCalendar(prev => ({
          ...prev,
          view: 'day',
        }));
      }
    },
    [modalCalendar],
  );

  const onChange = useCallback(
    (date: Date | null | unknown) => {
      if (
        modalCalendar.calendarKey != 'monthlyReport' &&
        (modalCalendar.view === 'year' || modalCalendar.view === 'month')
      ) {
        setModalCalendar(prev => ({
          ...prev,
          value: date as Date,
          localeDateString: setLocaleDateString(date as Date, 'kor'),
        }));
      } else {
        // 최종으로 값 결정 되는 구간 (modalCalendar.view === 'day')
        modalCalendar.onSuccess(date as Date);

        if (!modalCalendar.calendarKey.includes('reservation_deployment')) {
          onCloseModal();
        }
      }
    },
    [modalCalendar],
  );

  const onSettingView = useCallback(
    (type: string) => {
      let new_view: CalendarPickerView;
      if (type.includes('년')) {
        new_view = 'year';
      } else if (type.includes('월')) {
        new_view = 'month';
      } else {
        new_view = 'day';
      }
      setModalCalendar(prev => ({
        ...prev,
        view: new_view,
      }));
    },
    [modalCalendar],
  );

  const props_obj = {
    ...modalCalendar,
    minDate: modalCalendar.calendarKey.includes('reservation_deployment') ? modalCalendar.minDate : undefined,
    curYear,
    onCloseModal,
    onYearChange,
    onMonthChange,
    onSettingView,
    onChange,
  };

  return <ModalCalendarUI {...props_obj} />;
});
